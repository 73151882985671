/*

    Template Name : Tendoo

*/

/*

    Table Of Content

    01. Font Family
    02. Global Style
    03. Navigation Menu
    04. Explore Page (Home Screen)
    05. Cookbooks/Recipes Page
        05.01. Cookbook Profile Page
        05.02. Recipes Page
    06. Authors Page
        06.01. Author Profile Page
    07. Blog Page
        07.01. Blog Single Page
    08. Favorites Page
    09. Login
    10. Modals
    11. Search
    12. Settings
    13. Footer
        13.01. Get App Section
    14. Responsiv

*/

/* Bootstrap Style */
/* .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.col-xs {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
}
.col-xs-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
}
.col-xs-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
}
.col-xs-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}
.col-xs-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}
.col-xs-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}
.col-xs-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}
.col-xs-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}
.col-xs-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}
.col-xs-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
}
.col-xs-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
}
.col-xs-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
}
.col-xs-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}
@media (min-width: 768px) {
    .col-sm {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }
    .col-sm-1 {
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }
    .col-sm-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .col-sm-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-sm-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .col-sm-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }
    .col-sm-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-sm-7 {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }
    .col-sm-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
    .col-sm-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-sm-10 {
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }
    .col-sm-11 {
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }
    .col-sm-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}
@media (min-width: 992px) {
    .col-md {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }
    .col-md-1 {
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }
    .col-md-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .col-md-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-md-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .col-md-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }
    .col-md-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-md-7 {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }
    .col-md-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
    .col-md-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-md-10 {
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }
    .col-md-11 {
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }
    .col-md-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}
@media (min-width: 1200px) {
    .col-lg {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }
    .col-lg-1 {
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }
    .col-lg-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .col-lg-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-lg-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .col-lg-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }
    .col-lg-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-lg-7 {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }
    .col-lg-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
    .col-lg-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-lg-10 {
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }
    .col-lg-11 {
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }
    .col-lg-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
} */
/* End Bootstrap Style */

/* Font Family */
@font-face {
    font-family: "Branding";
    src: url("../fonts/branding/Branding-Thin.otf") format("opentype");
    font-weight: 100;
}
@font-face {
    font-family: "Branding";
    src: url("../fonts/branding/Branding-Semilight.otf") format("opentype");
    font-weight: 200;
}
@font-face {
    font-family: "Branding";
    src: url("../fonts/branding/Branding-Light.otf") format("opentype");
    font-weight: 300;
}
@font-face {
    font-family: "Branding";
    src: url("../fonts/branding/Branding-Medium.otf") format("opentype");
    font-weight: 500;
}
@font-face {
    font-family: "Branding";
    src: url("../fonts/branding/Branding-Semibold.otf") format("opentype");
    font-weight: 600;
}
@font-face {
    font-family: "Branding";
    src: url("../fonts/branding/Branding-Bold.otf") format("opentype");
    font-weight: 700;
}
@font-face {
    font-family: "Branding";
    src: url("../fonts/branding/Branding-Black.otf") format("opentype");
    font-weight: 900;
}
/* End Font Family */

/* Global Style */
html, body {
    font-family: 'Branding', sans-serif;
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	width: 100% !important;
	height: 100%;
    margin: 0 !important;
}
body {
    overflow-y: scroll;
    overflow-x: hidden;
}
a {
    cursor: pointer;
}
a,
a:hover,
a:focus {
    text-decoration: none;
}
h1 {
    font-family: 'Branding', sans-serif;
    font-size: 30px;
    font-weight: 300;
    line-height: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #0b0f1c;
}
h2 {
    font-family: 'Branding', sans-serif;
    font-size: 28px;
    font-weight: 300;
    line-height: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #0b0f1c;
}
h3 {
    font-family: 'Branding', sans-serif;
    font-size: 26px;
    font-weight: 300;
    line-height: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #0b0f1c;
}
p {
    font-family: 'Branding', sans-serif;
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8f8f8f;
}
h1,
h2,
h3,
p {
    margin: 0;
    padding: 0;
}
ul, ol {
	list-style: none;
    padding: 0;
    margin: 0;
}
li {
    list-style: none;
}
::selection {
    background-color: #ff5f00;
    color: #fff;
}
::-moz-selection {
    background-color: #ff5f00;
    color: #fff;
}
.equal {
    display: flex;
    flex-wrap: wrap;
}
.section-top-190 {
    margin-top: 190px;
}
.section-top-140 {
    margin-top: 140px;
}
.section-top-120 {
    margin-top: 120px;
}
.section-top-110 {
    margin-top: 110px;
}
.section-top-100 {
    margin-top: 100px;
}
.section-top-90 {
    margin-top: 90px;
}
.section-top-80 {
    margin-top: 80px;
}
.section-top-70 {
    margin-top: 70px;
}
.section-top-60 {
    margin-top: 60px;
}
.mg-top-130 {
    margin-top: 130px;
}
.mg-top-75 {
    margin-top: 75px;
}
.mg-top-15 {
    margin-top: 15px;
}
.mg-bottom-60 {
    margin-bottom: 60px;
}
.mg-bottom-30 {
    margin-bottom: 30px;
}
.mg-bottom-15 {
    margin-bottom: 15px;
}
.col-md-center {
    float: none;
    margin: 0 auto;
}
.align-right {
    float: right;
}
.main-title {
    margin-bottom: 60px;
}
.main-title h1 {
    font-size: 34px;
    font-weight: 600;
    color: #1a1a1a;
}
.main-title h2 {
    font-size: 20px;
    font-weight: 500;
    color: #1a1a1a;
}
.gen-link-more {
    float: left;
    width: 100%;
    position: relative;
    text-align: right;
}
.gen-link-more .more {
    position: relative;
    font-size: 16px;
    font-weight: 500;
    color: #ff5f00;
}
.gen-link-more .effect-underline:after {
    content: '';
    position: absolute;
    left: 0;
    display: inline-block;
    height: 16px;
    width: 100%;
    border-bottom: 1px solid;
    margin-top: 10px;
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: scale(0,1);
    transform: scale(0,1);
}
.gen-link-more .effect-underline:hover:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.message-alert {
    position: absolute;
    top: 0;
    height: 600px;
    width: 100%;
    z-index: 2;
}
.message-alert-overlay {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0) 72%, rgba(255, 255, 255, 0));
}
.message-alert-content {
    position: fixed;
    right: 20px;
    top: 90px;
    width: 500px;
    max-width: 100%;
    padding: 30px;
    border-radius: 23px;
    box-shadow: 0 10px 20px 0 rgba(26, 26, 26, 0.1);
    background-color: #ffffff;
    display: flex;
    align-items: center;
    animation: fadein 2s;
    -moz-animation: fadein 2s;
    -webkit-animation: fadein 2s;
    -o-animation: fadein 2s;
}
.message-alert-content-inner {
    margin-left: 30px;
}
.message-alert-content-inner h1 {
    font-size: 20px;
    font-weight: 500;
    color: #1a1a1a;
    margin-bottom: 10px;
}
.message-alert-content-inner p {
    font-size: 16px;
    font-weight: 500;
    color: #7a7a7a;
}
/* End Global Style */

/* Navigation Menu */
.header {
    width: 100%;
    float: left;
    position: relative;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    z-index: 1;
}
.header-inner {
    position: relative;
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    height: 80px;
}
.header-inner-left {
    position: relative;
    float: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header .logo {
    position: absolute;
    top: -30px;
    height: 120px;
    width: 150px;
    border-bottom-right-radius: 23px;
    border-bottom-left-radius: 23px;
    background-color: #ff5f00;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.header .logo a {
    text-align: center;
}
.header .logo img {
    max-width: 80%;
}
.header .nav-mobile {
    display: none;
    padding: 20px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    margin: 0 20px;
}
.header .nav-right {
    margin-left: auto;
}
.header .nav-right ul li {
    display: inline-block;
}
.header .nav-right ul li:not(:last-of-type) {
    margin-right: 30px;
}
.header .nav-menu-wrapp {
    margin-left: 210px;
    position: relative;
}
.header .nav-menu ul li {
    display: inline-block;
    position: relative;
}
.header .nav-menu ul li:not(:last-child) {
    margin-right: 45px;
}
.header .nav-menu ul li.active::before,
.header .nav-menu ul li:hover::before {
    content: "";
    position: absolute;
    width: 26px;
    height: 3px;
    background-color: #ff5f00;
    bottom: -30px;
}
.header .nav-menu ul li.active a {
    color: #ff5f00;
}
.header .nav-menu ul li a {
    font-family: 'Branding';
    font-size: 16px;
    font-weight: 500;
    color: #7a7a7a;
}
.header .nav-menu ul li a:hover,
.header .nav-menu ul li a:active,
.header .nav-menu ul li a:focus {
    color: #ff5f00;
}
.header .nav-end {
    position: relative;
    text-align: right;
}
.header .nav-end ul li {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
    cursor: pointer;
}
.header .nav-end ul li:not(:last-child) {
    margin-right: 25px;
}
.header .nav-end ul li a {
    font-size: 16px;
    font-weight: 500;
    color: #7a7a7a;
    cursor: pointer;
}
.header .nav-end ul li a:hover {
    color: #ff5f00;
}
.header .nav-end ul li.nav-login {
    padding: 14px 20px;
    border-radius: 13px;
    background-color: rgba(255, 95, 0, 0.06);
}
.header .nav-end ul li.nav-login a {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ff5f00;
}
.header .nav-end ul li.nav-login a svg {
    margin-right: 10px;
}
.header .nav-end ul li.nav-register {
    padding: 14px 20px;
    border-radius: 13px;
    background-color: #ff5f00;
}
.header .nav-end ul li.nav-register a {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}
.header .nav-end ul li.nav-register a svg {
    margin-right: 10px;
}
.header .nav-mobile ul li.nav-login a,
.header .nav-mobile ul li.nav-register a {
    color: #fff;
    font-size: 16px;
}
.header .nav-end ul li.nav-register a:hover svg path {
    fill: #fff;
}
.header .nav-end ul li a:hover svg path {
    fill: #ff5f00;
}
.header .nav-end ul li.click-search:hover svg path {
    fill: #ff5f00;
}
.header .nav-end ul li.active a svg path {
    fill: #ff5f00;
}
.header .nav-end .dropdown {
    margin-top: 0px;
}
.header .nav-profile {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    user-select: none;
}
.header .nav-profile .nav-profile-img {
    height: 40px;
    width: 40px;
    border-radius: 50px;
    overflow: hidden;
    margin-right: 10px;
}
.header .nav-profile .nav-profile-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.header .nav-profile .nav-profile-icon {
    width: 15px;
    height: 15px;
    border-radius: 7.5px;
    background-color: #ff5f00;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
}
.nav-profile-wrapp .dropdown-menu {
    border-radius: 13px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid #f0f0f0;
    background-color: #fff;
    margin-top: 12px;
    left: -105px;
}
.nav-profile-wrapp .dropdown-menu::before {
    content: '';
    display: block;
    position: absolute;
    top: -6px;
    left: auto;
    right: 30px;
    width: 10px;
    height: 10px;
    background: #fff;
    border-right: 1px solid #f0f0f0;
    border-top: 1px solid #f0f0f0;
    -moz-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}
.nav-profile-wrapp #dropdown-profile li {
    width: 100%;
    padding: 10px 20px;
    margin-right: 0px;
}
.nav-profile-wrapp #dropdown-profile li:last-child {
    border-top: 1px solid #f4f4f4;
    margin-top: 10px;
    padding: 20px;
}
.nav-profile-wrapp #dropdown-profile li a {
    font-size: 16px;
    font-weight: 300;
    color: #7a7a7a;
    padding: 0;
    position: relative;
    display: flex;
    align-items: center;
}
.nav-profile-wrapp #dropdown-profile li a svg {
    margin-right: 10px;
}
.nav-profile-wrapp #dropdown-profile li.active a,
.nav-profile-wrapp #dropdown-profile li a:hover {
    color: #ff5f00;
    background: #fff;
}
.main-menu-indicator {
    display: none;
    border: none;
    padding: 0;
    cursor: pointer;
    height: 30px;
    width: 25px;
    background: transparent;
    outline: 0;
    float: right;
    position: relative;
    margin-top: 0;
}
/* End Navigation Menu */

/* Explore Page (Home Screen) */
.explore-slider {
    float: left;
    width: 100%;
    position: relative;
    overflow-x: hidden;
}
.slick-controls {
    display: none;
    position: absolute;
    bottom: 56px;
}
.slick-controls .previous {
    position: relative;
    background-color: transparent;
    border: none;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    padding: 0;
    padding-right: 15px;
    margin-right: 15px;
}
.slick-controls .previous::before {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 11px;
    background-color: #fff;
}
.slick-controls .next {
    position: relative;
    background-color: transparent;
    border: none;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    padding: 0;
}
.slick-controls .previous:focus,
.slick-controls .next:focus {
    outline: none;
}
.main-explore-slider .item {
    height: 650px;
    overflow: hidden;
}
.main-explore-slider .item::before {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, #1a1a1a, rgba(26, 26, 26, 0.4));
    z-index: 1;
}
.main-explore-slider .item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.main-explore-slider .item .content {
    position: absolute;
    top: 250px;
    z-index: 1;
}
.main-explore-slider .item .content h1 {
    font-size: 58px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 25px;
}
.main-explore-slider .item .content p {
    font-size: 18px;
    font-weight: 500;
    color: #fff;
}
.main-explore-slider .item .content .date {
    display: block;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 15px;
}
.main-explore-slider .item .content .content-rate {
    margin-bottom: 15px;
}
.main-explore-slider .item .content .content-discover {
    margin-top: 90px;
}
.main-explore-slider .item .content .content-discover .button {
    border: 1px solid #ff5f00;
    background-color: #ff5f00;
    border-radius: 9px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    display: inline-block;
    cursor: pointer;
    padding: 15px 30px;
    transition: all .5s ease;
}
.main-explore-slider .item .content .content-discover .button:hover {
    transition: all .5s ease;
    background-color: #ff4000;
    border: 1px solid transparent;
}
.main-explore-slider .item .content .content-discover .button:focus {
    outline: none;
}
.second-explore-slider-wrapp {
    position: relative;
    width: 100%;
    top: -40px;
}
.second-explore-slider {
    position: absolute;
    width: 35%;
    bottom: 30px;
    right: 0;
}
.second-explore-slider .item {
    width: 215px;
    height: 130px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    margin-right: 15px;
    border-radius: 13px;
}
.second-explore-slider .item::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #1a1a1a;
    opacity: 0.5;
}
.second-explore-slider img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.second-explore-slider .slick-slide:focus {
    outline: none;
}
.second-explore-slider .item.slick-current {
    opacity: 1;
    border: 2px solid #ff5f00;
}
.second-explore-slider .item .content {
    padding: 15px;
    position: absolute;
    bottom: 0;
}
.second-explore-slider .item .content h1 {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 5px;
}
.second-explore-slider .item .content p {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
}
.cookbooks {
    float: left;
    width: 100%;
    position: relative;
}
.cookbooks-item {
    position: relative;
    padding-top: 10px;
    margin-bottom: 60px;
    float: left;
}
.cookbooks-img {
    height: 345px;
    width: 100%;
    overflow: hidden;
    border-radius: 23px;
}
.cookbooks-img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.cookbooks-content {
    margin-top: 20px;
}
.cookbooks-content h1 {
  font-size: 18px;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 5px;
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cookbooks-content span {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #ff5f00;
    margin-bottom: 15px;
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.cookbooks-content p {
    font-size: 14px;
    font-weight: 500;
    color: #7a7a7a;
}
.cookbooks-content-footer {
    width: 100%;
    position: relative;
    float: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.cookbooks-assets {
    position: absolute;
    top: 0px;
    right: 10px;
}
.recipes-assets {
    position: absolute;
    top: -10px;
    right: 10px;
}
.toggle-fav-book {
    position: absolute;
    top: 16px;
    left: 25%;
    transform: translateX(-50%);
    cursor: pointer;
}
.fav-book-on {
    background-image: url(../img/fav-cook-on.svg);
    background-position: 0 center;
    width: 18px;
    height: 16px;
}
.fav-book-off {
    background-image: url(../img/fav-cook-off.svg);
    background-position: 0 center;
    width: 18px;
    height: 16px;
}
.cookbooks-slider .slick-next {
    top: -80px;
    right: 15px;
    width: 10px;
    height: 20px;
}
.cookbooks-slider .slick-next:before{
    content: "";
    background: url('../img/right.svg');
    background-repeat: no-repeat;
    height: 20px;
    width: 10px;
    position: absolute;
    top: 0;
    left: 0;
}
.cookbooks-slider .slick-prev {
    top: -80px;
    left: auto;
    right: 45px;
    height: 20px;
    width: 10px;
    z-index: 2;
}
.cookbooks-slider .slick-prev:before{
    content: "";
    background: url('../img/left.svg');
    background-repeat: no-repeat;
    height: 20px;
    width: 10px;
    position: absolute;
    top: 0;
    left: 0;
}
.recipes {
    float: left;
    width: 100%;
    position: relative;
}
.recipes-item {
    position: relative;
    float: left;
    width: 100%;
}
.recipes-item-img {
    position: relative;
    height: 280px;
    overflow: hidden;
    border-radius: 23px;
    user-select: none;
    will-change: transform;
}
.recipes-item-img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.recipes-item-content-details {
    position: absolute;
    width: 100%;
    height: 17%;
    top: auto;
    right: 0;
    left: 0;
    bottom: 0;
    border-radius: 23px;
    background-image: -webkit-linear-gradient(rgba(26, 26, 26, 0), rgba(26, 26, 26, 0.8));
    background-image: -o-linear-gradient(rgba(26, 26, 26, 0), rgba(26, 26, 26, 0.8));
    background-image: linear-gradient(rgba(26, 26, 26, 0), rgba(26, 26, 26, 0.8));
    text-align: center;
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}
.recipes-item-content-details:hover {
    opacity: 1;
}
.recipes-item-content-details-inner {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
}
.recipes-item-content-details-inner ul li {
    display: inline-block;
    position: relative;
    border-bottom: 2px solid transparent;
    /* padding: 23px 10px; */
}
.recipes-item-content-details-inner ul li div a {
    position: relative;
    display: block;
    padding: 23px 10px;
}
.recipes-item-content-details-inner ul li:not(:last-child) {
    margin-right: 20px;
}
.recipes-item-content-details-inner ul li a:hover::before{
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: #ff5f00;
    bottom: -2px;
    left: 0;
}
.recipes-item-content-details-inner [data-tooltip]:before {
    bottom: 80%;
    width: auto;
    padding: 3px 10px;
    font-size: 12px;
}
.recipes-item-content-details-inner [data-tooltip]:after {
    bottom: 80%;
}
.recipes-asset {
    position: absolute;
    top: -10px;
    right: 10px;
}
.toggle-fav-recipe {
    position: absolute;
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
}
.fav-recipes-on {
    background-image: url(../img/fav-cook-on.svg);
    background-position: 0 center;
    width: 18px;
    height: 16px;
}
.fav-recipes-off {
    background-image: url(../img/fav-cook-off.svg);
    background-position: 0 center;
    width: 18px;
    height: 16px;
}
.recipes-content {
    position: relative;
    margin-top: 20px;
}
.recipes-content h1 {
    font-size: 18px;
    font-weight: 600;
    color: #1a1a1a;
    margin-bottom: 15px;
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.recipes-content span.recipes-time {
    padding: 3px 6px;
    border-radius: 6px;
    background-color: rgba(255, 95, 0, 0.15);
    font-size: 14px;
    font-weight: 300;
    color: #ff5f00;
    margin-right: 15px;
}
.recipes-content span.recipes-author {
    font-size: 14px;
    font-weight: 500;
    color: #7a7a7a;
}
.recipes-content-ft {
    float: left;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.recipes-tags {
    float: left;
    position: relative;
    width: 100%;
}
.recipes-tags h1 {
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    color: #1a1a1a;
}
.recipes-tags ul {
    padding-top: 15px;
    margin-top: 15px;
    border-top: 1px solid #f4f4f4;
}
.recipes-tags ul li {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
}
.recipes-tags ul li a {
    display: inline-block;
    padding: 8px;
    border-radius: 6px;
    background-color: #f4f4f4;
    font-size: 14px;
    font-weight: 300;
    line-height: normal;
    color: #7a7a7a;
    transition: all .5s ease;
}
.recipes-tags ul li a:hover {
    background-color: #ff5f00;
    color: #fff;
    transition: all .5s ease;
}
.recipes-tags-single {
    margin-top: 90px;
}
.cookbook-month {
    position: relative;
    width: 100%;
    float: left;
    padding-top: 100px;
    overflow: hidden;
}
.cookbook-month-bg {
    position: absolute;
    width: 100%;
    height: 710px;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
}
.cookbook-month-bg::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.8) 49%, #ffffff);
}
.cookbook-month-content {
    padding-top: 120px;
}
.cookbook-month-content .badge {
    border-radius: 0px;
    background-color: transparent;
    padding: 10px 0;
    font-size: 16px;
    font-weight: 700;
    color: #ff5f00;
    display: inline-flex;
    align-items: center;
}
.cookbook-month-content .badge img {
    margin-right: 25px;
}
.cookbook-month-content h1 {
    font-size: 40px;
    font-weight: 700;
    line-height: 1.1;
    color: #1a1a1a;
    margin-bottom: 15px;
}
.cookbook-month-content-author {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}
.cookbook-month-content-author .author {
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    color: #ff5f00;
    margin-right: 82px;
}
.cookbook-month-content .rating {
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    color: #1a1a1a;
    display: flex;
    align-items: center;
}
.cookbook-month-content p {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    color: #1a1a1a;
    padding-bottom: 30px;
    word-break: break-word;
}
.cookbook-month-content .button {
    border: 1px solid #ff5f00;
    background-color: #ff5f00;
    border-radius: 9px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    display: inline-block;
    cursor: pointer;
    padding: 15px 30px;
    transition: all .5s ease;
    margin-bottom: 35px;
}
.cookbook-month-content .button:hover {
    transition: all .5s ease;
    background-color: #ff4000;
    border: 1px solid transparent;
}
.cookbook-month-img {
    position: relative;
    text-align: right;
}
.cookbook-month-mockup img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}
.cookbook-month-slider {
    position: relative;
    width: 100%;
}
.cookbook-month-slider .title {
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    color: #1a1a1a;
    margin-bottom: 24px;
}
.cookbook-month-slider .slick-list {
    width: 100vw;
}
.cookbook-month-controls {
    position: absolute;
    top: -10px;
    right: 0;
    display: flex;
}
.cookbook-month-controls button {
    /* height: 16px;
    width: 10px; */
    background-color: transparent;
    margin: 0 10px;
    border: none;
}
.cookbook-month-controls button:focus {
    outline: none;
}
.cookbook-month-controls button:focus svg path{
    fill: #ff5f00;
}
.cookbook-month-item {
    width: 215px;
    height: 130px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    margin-right: 15px;
    border-radius: 13px;
}
.cookbook-month-item::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #1a1a1a;
    opacity: 0.5;
}
.cookbook-month-item .content {
    padding: 15px;
    position: absolute;
    bottom: 0;
}
.cookbook-month-item .content h1 {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
}
.cookbook-month-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
/* End Explore Page (Home Screen) */

/* Cookbooks/Recipes Page */
.filters {
    position: relative;
    width: 100%;
    float: left;
    padding: 70px 0 30px;
}
.filters-cookbook {
    padding: 130px 0 10px;
}
.filters-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}
.filters-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.filters-title h1 {
    font-size: 24px;
    font-weight: 700;
    color: #1a1a1a;
    margin-right: 40px;
}
.filters-title a {
    font-size: 16px;
    font-weight: 300;
    color: #7a7a7a;
    display: flex;
    align-items: center;
}
.filters-title a svg {
    margin-left: 10px;
}
.filter-inner-title {
    font-size: 16px;
    font-weight: 500;
    color: #1a1a1a;
}
.dropdown-wrapp {
    width: 100%;
    float: left;
    position: relative;
    margin-bottom: 40px;
}
.dropdown {
    margin-top: 10px;
}
.dropdown-toggle {
    width: 100%;
    padding: 0;
    border-radius: 13px;
    border: 1px solid #ebebeb;
    background-color: #fff;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    font-size: 14px;
    font-weight: 300;
    line-height: normal;
    letter-spacing: normal;
    color: #7a7a7a;
}
.dropdown-toggle[aria-expanded="true"] {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}
.dropdown-wrapp .dropdown-menu {
    width: 100%;
    border: 1px solid #ebebeb;
    background-color: #fff;
    border-radius: 0;
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
    margin: 0;
    padding: 0;
    box-shadow: none;
}
.dropdown.open .dropdown-toggle {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.filters-button .button {
    width: 270px;
    max-width: 100%;
    height: 40px;
    border-radius: 13px;
    background-color: #7a7a7a;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    border: 0px;
    transition: all .5s ease;
}
.filters-button .button:hover {
    background-color: #ff5f00;
    transition: all .5s ease;
}
.filters-button .button:focus {
    outline: none;
}
.filters-button .button:disabled,
.filters-button .button[disabled] {
    background-color: #7a7a7a;
}
.range-wrapp {
    width: 100%;
    float: left;
    position: relative;
    margin-bottom: 40px;
    z-index: 0;
}
.range-wrapp .range-head {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22px;
}
.range-wrapp .range-head p {
    font-size: 14px;
    font-weight: 500;
    color: #ff5f00;
}
#range-time,
#range-cal {
    border: 0;
    height: 1px;
    outline: none;
    border-radius: 23px;
    background-color: rgb(206,206,206);
}
#range-time .ui-slider-handle,
#range-cal .ui-slider-handle {
    position: absolute;
    margin: -10px 0 0 -10px;
    border-radius: 100%;
    width: 26px;
    height: 26px;
    border: 1px solid #ff5f00;
    background-color: #fff;
    outline: none;
    cursor: pointer;
}
#range-time .ui-slider-handle:hover,
#range-cal .ui-slider-handle:hover,
#range-time .ui-slider-handle:focus,
#range-cal .ui-slider-handle:focus {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}
#range-time .ui-slider-range,
#range-cal .ui-slider-range {
    background: #ff5f00;
}
.select-wrapp {
    position: relative;
    width: 100%;
    padding: 10px;
}
.select-wrapp .select-title {
    display: block;
    font-size: 16px;
    font-weight: 500;
    color: #7a7a7a;
    margin-bottom: 10px;
}
.select-wrapp .category-item {
    position: relative;
    width: 100%;
    float: left;
    text-align: center;
    margin-bottom: 20px;
}
.select-wrapp .category-img {
    width: 80px;
    height: 80px;
    border-radius: 50px;
    border: 1px dotted #bebebe;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}
.select-wrapp .category-title {
    display: block;
    font-size: 16px;
    font-weight: 300;
    color: #7a7a7a;
    margin-top: 10px;
}
.select-wrapp .category-item:hover .category-title {
    color: #ff5f00;
}
.select-wrapp .category-item:hover .category-img {
    border: 1px dotted #ff5f00 !important;
}
.select-wrapp .category-item:hover .category-img svg g {
    stroke: #ff5f00;
}
.select-wrapp .dropdown-ingredients {
    padding: 10px;
}
.select-wrapp .add-ingredients {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
}
.select-wrapp .add-ingredients .inp-add-ingr {
    width: 100%;
    height: 50px;
    border-radius: 8px;
    background-color: #f4f4f4;
    border: 0;
    border-radius: 13px;
    box-shadow: none;
    transition: all .7s ease;
    padding: 15px 50px 15px 10px;
    font-size: 14px;
    font-weight: 300;
    color: #7a7a7a;
}
.select-wrapp .add-ingredients .inp-add-ingr:focus {
    outline: none;
}
.select-wrapp .add-ingredients svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
}
.select-wrapp ul.ingredients-list li {
    height: 42px;
    border-radius: 25px;
    border: 1px solid #ebebeb;
    padding: 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.select-wrapp ul.ingredients-list li:not(:last-child) {
    margin-bottom: 10px;
}
.select-wrapp ul.ingredients-list li .ingredient-content {
    display: flex;
    align-items: center;
}
.select-wrapp ul.ingredients-list li .ingredient-content span {
    font-size: 16px;
    font-weight: 500;
    color: #7a7a7a;
}
.select-wrapp ul.ingredients-list li .ingredient-img {
    border-radius: 50px;
    border: solid 1px #ebebeb;
    height: 36px;
    width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    overflow: hidden;
}
.select-wrapp ul.ingredients-list li .ingredient-img img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
}
.select-wrapp ul.ingredients-list li .ingredient-delete{
    border-radius: 50px;
    background-color: #ececec;
    height: 36px;
    width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.select-wrapp ul.ingredients-list li a:hover .ingredient-delete{
    background-color: rgba(255, 0, 0, 0.2);
}
.select-wrapp ul.ingredients-list li a:hover .ingredient-delete svg path{
    fill: #f00;
}
.select-wrapp ul li {
    padding: 5px 0;
}
.select-wrapp ul li a {
    font-size: 16px;
    font-weight: 300;
    line-height: normal;
    color: #7a7a7a;
}
.flex-bottom {
    display: flex;
}
.filters-category {
    position: relative;
    width: 100%;
    padding-top: 30px;
    border-top: 1px solid #ebebeb;
}
.filters-category-head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}
.filters-category-head-title h1 {
    font-size: 16px;
    font-weight: 700;
    color: #1a1a1a;
}
.filters-category-head-more a {
    display: block;
    font-size: 16px;
    font-weight: 500;
    color: #ff5f00;
}
.filters-category-items-wrap {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    position: relative;
}
.filters-category-item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    border: 1px dashed #bebebe;
    border-radius: 40px;
    min-height: 80px;
    overflow: hidden;
    cursor: pointer;
    margin: 5px 10px;
    flex: 0 1 calc(16.66% - 20px);
}
.filters-category-icon {
    margin-right: 5px;
}
.filters-category-title span {
    font-size: 16px;
    font-weight: 300;
    color: #7a7a7a;
}
.filters-category-item.active {
    border-color: #ff5f00;
}
.filters-category-item.active .filters-category-title span {
    color: #ff5f00;
}
/* End Cookbooks/Recipes Page */

/* Cookbook Profile Page */
.cookbook-profile {
    float: left;
    width: 100%;
    position: relative;
}
.cookbook-profile-banner {
    float: left;
    position: absolute;
    width: 100%;
    height: 670px;
    overflow: hidden;
}
.cookbook-profile-banner::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.85), #ffffff 72%, #ffffff);
}
.cookbook-profile-banner img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.cookbook-profile-info {
    position: relative;
    float: left;
    width: 100%;
}
.cookbook-profile-img {
    width: 100%;
    height: 700px;
    overflow: hidden;
    border-radius: 23px;
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
}
.cookbook-profile-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.cookbook-profile-info-inner {
    position: relative;
    margin-bottom: 30px;
}
.cookbook-profile-info-inner h1 {
    font-size: 42px;
    font-weight: 600;
    color: #1a1a1a;
    margin-bottom: 46px;
}
.cookbook-profile-info-inner h2 {
    font-size: 20px;
    font-weight: 500;
    color: #1a1a1a;
    margin-bottom: 20px;
}
.cookbook-profile-info-inner p {
    font-size: 18px;
    font-weight: 300;
    line-height: 30px;
    color: #7a7a7a;
    word-break: break-word;
}
.cookbook-profile-info-inner p:not(:last-of-type) {
    padding-bottom: 30px;
}
.cookbooks-profile-stats {
    margin-bottom: 60px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.cookbooks-profile-stats span.stats {
    font-size: 20px;
    font-weight: 300;
    color: #7a7a7a;
    display: flex;
    align-items: center;
}
.cookbooks-profile-stats span.stats svg {
    margin-top: 2px;
    margin-right: 2px;
}
.cookbooks-profile-stats span.number {
    font-weight: 500;
    margin-right: 5px;
}
.cookbook-profile-content {
    position: relative;
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 60px;
}
.cookbook-author-info-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}
.cookbook-author-img {
    width: 70px;
    height: 80px;
    border-radius: 13px;
    overflow: hidden;
    margin-right: 15px;
}
.cookbook-author-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.cookbook-author-content h1 {
    font-size: 20px;
    font-weight: 500;
    color: #1a1a1a;
    margin-bottom: 10px;
}
.cookbook-author-content a {
    font-size: 16px;
    font-weight: 500;
    color: #7a7a7a;
}
.cookbook-author-content a:hover {
    color: #ff5f00;
    transition: all .7s ease;
    -webkit-transition: all .7s ease;
    -moz-transition: all .7s ease;
    -o-transition: all .7s ease;
}
.cookbook-profile-share {
    position: relative;
}
.cookbook-profile-share ul li {
    display: inline-block;
}
.cookbook-profile-share ul li:not(:last-child) {
    margin-right: 15px;
}
.cookbook-profile-share ul li a {
    width: 46px;
    height: 46px;
    border-radius: 9px;
    border: 1px solid rgba(122, 122, 122, 0.2);
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cookbook-profile-share ul li a:hover {
    border: 1px solid #ff5f00;
    -webkit-transition: all .7s ease;
    -moz-transition: all .7s ease;
    -o-transition: all .7s ease;
}
.cookbook-profile-share ul li a:hover svg path {
    fill: #ff5f00;
    transition: all .7s ease;
    -webkit-transition: all .7s ease;
    -moz-transition: all .7s ease;
    -o-transition: all .7s ease;
}
.cookbook-profile-subscribe {
    position: relative;
    float: left;
    width: 100%;
}
.cookbook-profile-subscribe .button {
    max-width: 100%;
    width: 270px;
    height: 45px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ff5f00;
    border-radius: 9px;
    cursor: pointer;
    background-color: #ff5f00;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    transition: all .5s ease;
}
.cookbook-profile-subscribe .button:hover {
    background-color: #ff4000;
    border: 1px solid transparent;
    transition: all .5s ease;
}
.cookbook-profile-nav {
    position: relative;
    width: 100%;
    float: left;
    border-bottom: 1px solid #f4f4f4;
    margin: 90px 0 60px;
}
.cookbook-profile-nav ul li {
    position: relative;
    display: inline-block;
}
.cookbook-profile-nav ul li a {
    font-size: 16px;
    font-weight: 500;
    color: #7a7a7a;
    padding: 15px 0px;
    display: block;
}
.cookbook-profile-nav ul li:hover a,
.cookbook-profile-nav ul li.active a {
    color: #ff5f00;
}
.cookbook-profile-nav ul li.active::before,
.cookbook-profile-nav ul li:hover::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 3px;
    background-color: #ff5f00;
    border-radius: 13px;
}
.cookbook-profile-nav ul li:not(:last-child) {
    margin-right: 30px;
}
.cookbook-profile-rate {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.cookbook-profile-rate h2 {
  font-size: 20px;
  font-weight: 500;
  color: #1A1A1A;
  margin-right: 20px;
}
/* End Cookbook Profile Page */

/* Recipes Page */
.recipes-profile {
    float: left;
    width: 100%;
    position: relative;
}
.recipes-profile-banner {
    float: left;
    position: absolute;
    width: 100%;
    height: 365px;
    overflow: hidden;
}
.recipes-profile-banner::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.55), rgba(255, 255, 255, 0.85) 72%, #ffffff);
}
.recipes-profile-banner img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.recipes-profile-info {
    position: relative;
    float: left;
    width: 100%;
}
.recipes-profile-info-inner {
    position: relative;
    margin-bottom: 30px;
}
.recipes-profile-info-inner .title {
    font-size: 42px;
    font-weight: 600;
    color: #1a1a1a;
    margin-bottom: 60px;
}
.recipes-profile-info-inner h2 {
    font-size: 20px;
    font-weight: 500;
    color: #1a1a1a;
    margin-bottom: 15px;
}
.recipes-profile-info-inner p {
    font-size: 18px;
    font-weight: 300;
    line-height: 30px;
    color: #7a7a7a;
    word-break: break-word;
}
.recipes-profile-info-inner p:not(:last-of-type) {
    padding-bottom: 30px;
}
.recipe-profile-img {
    position: relative;
}
.recipe-profile-img-wrap {
    position: relative;
    width: 100%;
    height: 350px;
    border-radius: 23px;
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
    overflow: hidden;
}
.recipe-profile-img-wrap img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.recipes-play {
    width: 185px;
    padding: 25px 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 40px;
    background-color: #ff5f00;
    margin: 0 auto;
    margin-top: 30px;
    user-select: none;
}
.recipes-play a {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.recipes-play span {
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
}
.recipes-play-forward {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.recipes-play-forward img {
    width: 80px;
    height: 80px;
}
.recipes-values {
    position: relative;
    float: left;
    width: 100%;
}
.recipes-values-wrap h1 {
    font-size: 20px;
    font-weight: 500;
    color: #1a1a1a;
    margin-bottom: 45px;
}
.recipes-table table {
    width: 100%;
}
.recipes-table table tr th {
    font-size: 16px;
    font-weight: 500;
    color: #7a7a7a;
}
.recipes-table table tr th {
    font-size: 18px;
    font-weight: 500;
    color: #ff5f00;
}
.recipes-time-wrap {
    position: relative;
    float: left;
    width: 100%;
    border-radius: 13px;
    border: 1px dotted #7a7a7a;
    padding: 40px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.recipes-time-item svg {
    margin-bottom: 20px
}
.recipes-time-item p {
    font-size: 20px;
    font-weight: 300;
    color: #7a7a7a;
    margin-bottom: 15px;
}
.recipes-time-item span {
    font-weight: 500;
    color: #1a1a1a;
}
.recipes-time-item h1 {
    font-size: 20px;
    font-weight: 500;
    color: #7a7a7a;
}
.recipes-extra {
    position: relative;
    width: 100%;
    float: left;
}
.recipes-extra-ingredients-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 13px;
    border: 1px solid #e3e3e3;
    margin-bottom: 20px;
}
.recipes-extra-ingredients-head h1 {
    font-size: 16px;
    font-weight: 500;
    color: #1a1a1a;
}
.recipes-extra-ingredients-item {
    position: relative;
    width: 100%;
    border-radius: 20px;
    border: 1px solid #e3e3e3;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.recipes-extra-ingredients-content {
    display: flex;
    align-items: center;
}
.recipes-extra-ingredients-img {
    height: 60px;
    width: 60px;
    overflow: hidden;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    flex: 0 0 60px;
}
.recipes-extra-ingredients-img img {
    max-width: 100%;
    height: 100%;
}
.recipes-extra-ingredients-content span {
    font-size: 16px;
    font-weight: 500;
    color: #7a7a7a;
}
.recipes-extra-ingredients-quantity {
    text-align: right;
}
.recipes-extra-ingredients-quantity span {
    font-size: 14px;
    font-weight: 500;
    color: #ff5f00;
}
.recipes-extra-title h1 {
    font-size: 20px;
    font-weight: 500;
    color: #1a1a1a;
    margin-bottom: 30px;
}
.recipes-extra-banner {
    width: 100%;
    max-width: 100%;
    height: 450px;
    border-radius: 23px;
    overflow: hidden;
}
.recipes-extra-banner img {
    height: 100%;
    /* width: 100%; */
    object-fit: cover;
}
.recipes-extra-table {
    position: relative;
    width: 370px;
    max-width: 100%;
}
.recipes-extra-table-inner {
    overflow: hidden;
    height: 450px;
    border-radius: 23px;
    border: 1px solid rgba(122, 122, 122, 0.2);
    padding: 20px 2px 0px 20px;
    margin-bottom: 20px;
}
.recipes-extra-table-wrap {
    position: relative;
    height: 410px;
    overflow: auto;
    scrollbar-color: #ececec #7a7a7a;
    scrollbar-width: thin;
}
.recipes-extra-table-wrap::-webkit-scrollbar {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    height: 410px;
}
.recipes-extra-table-wrap::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: #ececec;
}
.recipes-extra-table-wrap::-webkit-scrollbar-thumb {
    opacity: 0.8;
    border-radius: 3px;
    background-color: #7a7a7a;
}
.recipes-extra-table table {
    width: 100%;
}
.recipes-extra-table table tr td {
    font-size: 16px;
    font-weight: 500;
    color: #7a7a7a;
    padding: 0 20px 20px 0;
}
.recipes-extra-table table tr:last-of-type td {
    padding: 0 20px 0px 0;
}
.recipes-extra-table table .tb-quantity {
    color: #ff5f00;
    text-align: right;
}
.recipes-table-img {
    width: 60px;
    height: 60px;
    border-radius: 20px;
    border: 1px solid #e3e3e3;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.recipes-table-img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.add-shopping-list {
    margin: 20px 0 30px;
}
.add-shopping-list .button {
    min-width: 100%;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 9px;
    cursor: pointer;
    background-color: #ff5f00;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
}
.recipes-buy-img {
    width: 100%;
    max-width: 100%;
    height: 450px;
    border-radius: 23px;
    border: 1px solid rgba(122, 122, 122, 0.4);
    margin-bottom: 20px;
    overflow: hidden;
}
.recipes-buy-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.recipes-buy-button .button {
    min-width: 100%;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 9px;
    cursor: pointer;
    background-color: rgba(122, 122, 122, 0.15);
    font-size: 14px;
    font-weight: 500;
    color: #7a7a7a;
}
.recipes-howto-wrap {
    position: relative;
    float: left;
    width: 100%;
}
.recipes-howto-wrap h1 {
    font-size: 20px;
    font-weight: 500;
    color: #1a1a1a;
    margin-bottom: 60px;
}
.recipes-howto-item {
    position: relative;
    float: left;
    cursor: grab;
}
.recipes-howto-item:not(:last-of-type) {
    margin-right: 30px;
}
.recipes-howto-item:focus {
    outline: none;
}
.recipes-howto-item .recipes-howto-img {
    width: 300px;
    height: 210px;
    overflow: hidden;
    border-radius: 23px;
    margin-bottom: 25px;
}
.recipes-howto-item .recipes-howto-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.recipes-howto-item h1 {
    font-size: 16px;
    font-weight: 600;
    color: #1a1a1a;
    margin-bottom: 0px;
}
.recipes-howto-shade {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 200px;
    height: 283px;
    background-image: linear-gradient(to left, #ffffff, rgba(255, 255, 255, 0.97) 24%, rgba(255, 255, 255, 0.92) 40%, rgba(255, 255, 255, 0));
}
.recipes-time-item:not(:last-of-type) {
    margin-right: 160px;
}
.quantity-input {
    display: flex;
    justify-content: center;
    align-items: center;
}
.quantity-input .number-input {
    display: flex;
    align-items: center;
}
.quantity-input .change {
    background-color: transparent;
    border: 1px solid #7a7a7a;
    color: #7a7a7a;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    height: 24px;
    width: 24px;
    border-radius: 5px;
    user-select: none;
}
.quantity-input .input-box {
    text-align: center;
    border: none;
    width: 50px;
    height: 35px;
    color: #7a7a7a;
    font-size: 24px;
    background: transparent;
}
.quantity-input .input-box:focus {
    outline: none;
}
.recipes-comment {
    width: 100%;
    float: left;
    position: relative;
}
.recipes-comment-inner {
    padding: 0 20px;
    border-radius: 13px;
    border: 1px solid #e3e3e3;
    background-color: #fff;
}
.recipes-comment-head {
    position: relative;
    padding: 20px 0;
    border-bottom: 1px solid #e3e3e3;
}
.recipes-comment-head h1 {
    font-size: 16px;
    font-weight: 600;
    color: #1a1a1a;
}
.recipes-comment-input {
    float: left;
    width: 100%;
    position: relative;
    padding: 30px 0;
    border-bottom: 1px solid #efeeed;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.recipes-comment-input input {
    position: relative;
    border-radius: 9px;
    border: 1px solid #f0f0f0;
    background-color: #f0f0f0;
    height: 50px;
    resize: none;
    width: 90%;
    padding: 15px;
    font-size: 14px;
    font-weight: 500;
    color: #7a7a7a;
}
.recipes-comment-input input:focus {
    outline: none;
    border: 1px solid #ff5f00;
    background-color: #fff;
    transition: all .7s ease;
    -webkit-transition: all .7s ease;
    -moz-transition: all .7s ease;
    -o-transition: all .7s ease;
}
.recipes-comment-input a {
    font-size: 16px;
    font-weight: 500;
    color: #ff5f00;
}
.recipes-comment-item {
    position: relative;
    width: 100%;
    float: left;
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
}
.recipes-comment-item:not(:last-of-type) {
    border-bottom: 1px solid #e3e3e3;
}
.recipes-comment-info {
    position: relative;
    width: 30%;
}
.recipes-comment-info-inner {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.recipes-comment-img {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50px;
    margin-right: 10px;
}
.recipes-comment-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.recipes-comment-info-name h1 {
    font-size: 16px;
    font-weight: 300;
    color: #7a7a7a;
}
.recipes-comment-info span.date {
    display: block;
    font-size: 16px;
    font-weight: 300;
    color: #7a7a7a;
}
.recipes-comment-content {
    width: 70%;
    overflow: hidden;
    word-wrap: break-word;
}
.recipes-comment-content p {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    color: #1a1a1a;
    padding-bottom: 30px;
}
.recipes-comment-toolbar ul li {
    display: inline-block;
}
.recipes-comment-toolbar ul li:not(:last-child) {
    margin-right: 30px;
}
.recipes-comment-toolbar ul li a {
    font-size: 16px;
    font-weight: 300;
    color: #7a7a7a;
    display: flex;
    align-items: center;
}
.recipes-comment-toolbar ul li a svg {
    margin-right: 10px;
}
.recipes-comment-footer {
    width: 100%;
    position: relative;
    float: left;
    text-align: center;
    padding: 15px 0;
}
.recipes-comment-footer a {
    font-size: 16px;
    font-weight: 500;
    color: #ff5f00;
}
.tags {
    position: relative;
    width: 100%;
    float: left;
}
.tags-head {
    margin: 75px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.tags-head h1 {
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    color: #1a1a1a;
}
.tags-head span {
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    color: #7a7a7a;
}
/* End Recipes Page */
/* End Cookbooks/Recipes Page */

/* Authors Page */
.authors-banner {
    position: relative;
    width: 100%;
    float: left;
    height: 650px;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}
.authors-banner::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(to right, #1a1a1a, rgba(26, 26, 26, 0.4));
}
.author-banner-content {
    position: absolute;
    top: 60%;
    transform: translateY(-60%);
}
.author-banner-content h1 {
    font-size: 58px;
    font-weight: 700;
    line-height: 64px;
    color: #fff;
    margin-bottom: 25px;
}
.author-banner-content p {
    font-size: 18px;
    font-weight: 500;
    color: #fff;
}
.authors {
    position: relative;
    float: left;
    width: 100%;
}
.authors-main-title h1 {
    font-size: 42px;
    font-weight: 700;
    line-height: 1.52;
    color: #1a1a1a;
    margin-bottom: 20px;
}
.authors-main-title p {
    font-size: 18px;
    font-weight: 500;
    color: #1a1a1a;
}
.authors-item {
    position: relative;
    margin-bottom: 60px;
}
.authors-img {
    width: 100%;
    height: 280px;
    overflow: hidden;
    margin-bottom: 20px;
    border-radius: 13px;
}
.authors-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.authors-content .authors-title {
    font-size: 22px;
    font-weight: 600;
    color: #1a1a1a;
    margin-bottom: 10px;
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.authors-content .authors-content {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: #7a7a7a;
    margin-top: 15px;
}
.authors-book {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.authors-book .authors-book-number {
    font-size: 14px;
    font-weight: 500;
    color: #7a7a7a;
}
.author-services {
    position: relative;
    width: 100%;
    float: left;
}
.author-services-item {
    position: relative;
}
.author-services-icon {
    height: 60px;
    display: flex;
    align-items: center;
}
.author-services-content {
    position: relative;
    word-break: break-word;
}
.author-services-content h1 {
    font-size: 24px;
    font-weight: 700;
    color: #1a1a1a;
    margin: 30px 0;
}
.author-services-content p {
    font-size: 16px;
    font-weight: 500;
    color: #1a1a1a;
}
/* Author Profile Page */
.author-profile {
    float: left;
    width: 100%;
    position: relative;
}
.author-profile-banner {
    position: relative;
    width: 100%;
    height: 325px;
}
.author-profile-banner img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.author-profile-banner::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5);
}
.author-profile-img {
    height: 280px;
    width: 230px;
    max-width: 100%;
    overflow: hidden;
    margin-bottom: 30px;
    margin-top: -185px;
}
.author-profile-img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 13px;
}
.author-profile-info h1 {
    font-size: 26px;
    font-weight: 600;
    color: #1a1a1a;
    margin-bottom: 30px;
}
.author-profile-description {
    margin-top: 30px;
    position: relative;
}
.author-profile-description h1 {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    color: #1a1a1a;
    margin-bottom: 20px;
}
.author-profile-description p {
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    color: #7a7a7a;
}
.author-profile-subsribe {
    position: relative;
    margin-bottom: 50px
}
.author-profile-subsribe a {
    border: 1px solid #ff5f00;
    background-color: #ff5f00;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    padding: 8px 0;
    border-radius: 9px;
    text-align: center;
    display: inline-block;
    width: 230px;
    max-width: 100%;
    transition: all .5s ease;
}
.author-profile-subsribe a:hover {
    border: 1px solid transparent;
    background-color: #ff4000;
    transition: all .5s ease;
}
.author-profile-link {
    width: 100%;
    position: relative;
}
.author-profile-link ul li {
    width: 100%;
    display: flex;
    align-items: center;
}
.author-profile-link ul li:not(:last-child) {
    margin-bottom: 20px;
}
.author-profile-link a.icon {
    position: relative;
    float: left;
    width: 44px;
    height: 44px;
    border-radius: 9px;
    background-color: #ff5f00;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
    transition: all .5s ease;
}
.author-profile-link a.icon:hover {
    background-color: #ff4000;
    transition: all .5s ease;
}
.author-profile-link a.link {
    font-size: 16px;
    font-weight: 500;
    color: #7a7a7a;
}
.more-cookbooks {
    position: relative;
    width: 100%;
    float: left;
}
.more-cookbooks-slider {
    position: relative;
    width: 100%;
    float: left;
}
.more-cookbooks-slider .slick-next {
    top: -70px;
    right: 15px;
    width: 10px;
    height: 20px;
}
.more-cookbooks-slider .slick-next:before{
    content: "";
    background: url('../img/right.svg');
    background-repeat: no-repeat;
    height: 20px;
    width: 10px;
    position: absolute;
    top: 0;
    left: 0;
}
.more-cookbooks-slider .slick-prev {
    top: -70px;
    left: auto;
    right: 45px;
    height: 20px;
    width: 10px;
    z-index: 2;
}
.more-cookbooks-slider .slick-prev:before{
    content: "";
    background: url('../img/left.svg');
    background-repeat: no-repeat;
    height: 20px;
    width: 10px;
    position: absolute;
    top: 0;
    left: 0;
}
/* End Author Profile Page */
/* End Authors Page */

/* Blog Page */
.blog {
    position: relative;
    width: 100%;
    float: left;
}
.blog-item {
    margin-bottom: 60px;
    position: relative;
}
.blog-img {
    height: 220px;
    overflow: hidden;
    margin-bottom: 15px;
    border-radius: 13px;
    box-shadow: 0 5px 15px 0 rgba(122, 122, 122, 0.15);
    background-color: #d8d8d8;
}
.blog-img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.blog-content {
    position: relative;
    margin-bottom: 15px;
}
.blog-content h1.blog-title {
    font-size: 18px;
    font-weight: 500;
    color: #1a1a1a;
    margin-bottom: 10px;
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.blog-content p.blog-text {
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
    color: #7a7a7a;
}
.blog-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.blog-info .blog-date {
    font-size: 16px;
    font-weight: 300;
    color: #ff5f00;
}
.blog-info .blog-author {
    font-size: 16px;
    font-weight: 300;
    color: #7a7a7a;
}
.pagination-wrapper {
    text-align: center;
}
.pagination {
    margin: 0;
}
ul.pagination li a {
    margin: 0 5px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 300;
    color: #7a7a7a;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span{
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}
.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
    z-index: 2;
    color: #ff5f00;
    background-color: #fff;
    border-color: #ff5f00;
}
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
    color: #ff5f00;
    background-color: #fff;
    border-color: #ff5f00;
}
/* Blog Single Page */
.blog-single-banner {
    position: relative;
    width: 100%;
    float: left;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    height: 650px;
}
.blog-single-banner-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to top, rgba(26, 26, 26, 0.8), rgba(26, 26, 26, 0.2));
}
.blog-single-banner-content {
    position: absolute;
    bottom: 60px;
}
.blog-single-banner-content h1 {
    font-size: 42px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 45px;
}
.blog-single-banner-content .date {
    display: block;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 300;
    color: #fff;
    float: left;
}
.blog-single-banner-content .author {
    display: block;
    float: left;
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    margin-right: 30px;
}
.blog-article {
    position: relative;
    width: 100%;
    float: left;
}
.blog-single-content {
    margin-bottom: 80px;
    word-wrap: break-word;
}
.blog-single-content p {
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    color: #7a7a7a;
    padding-bottom: 40px;
}
.blog-single-content img {
    margin: 20px 0 60px;
}
.blog-single-share {
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #e3e3e3;
    border-bottom: 1px solid #e3e3e3;
}
.blog-single-share span {
    font-size: 16px;
    font-weight: 500;
    color: #929292;
}
.blog-single-share ul li {
    display: inline-block;
}
.blog-single-share ul li:not(:last-child){
    margin-right: 40px;
}
.blog-single-share ul li:hover a svg path{
    fill: #ff5f00;
}
.blog-similar-posts-title {
    text-align: center;
    margin-bottom: 60px;
}
.blog-similar-posts-title h1 {
    font-size: 24px;
    font-weight: 500;
    color: #1a1a1a;
}
/* End Blog Single Page */
/* End Blog Page */

/* Favorites Page */
.favorites {
    position: relative;
    float: left;
    width: 100%;
}
.favorites-title {
    position: relative;
    margin-top: 70px;
    margin-bottom: 30px;
}
.favorites-title h1 {
    font-size: 34px;
    font-weight: 500;
    color: #1a1a1a;
}
.favorites-nav {
    position: relative;
    width: 100%;
    float: left;
    padding: 0 15px;
}
.favorites-nav ul {
    position: relative;
    width: 100%;
    float: left;
    text-align: center;
    border-bottom: 1px solid #e3e3e3;
    margin-bottom: 80px;
}
.favorites-nav ul li {
    position: relative;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    color: #7a7a7a;
}
.favorites-nav ul li:not(:last-child) {
    margin-right: 26px;
}
.favorites-nav ul li a {
    font-size: 16px;
    font-weight: 500;
    color: #7a7a7a;
    padding: 20px 18px;
    display: block;
}
.favorites-nav ul li:hover a,
.favorites-nav ul li.active a {
    color: #ff5f00;
}
.favorites-nav ul li:hover::before,
.favorites-nav ul li.active::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #ff5f00;
    bottom: -2px;
    left: 0;
    border-radius: 23px;
}
.favorites-empty {
    text-align: center;
    margin-bottom: 100px
}
.favorites-empty h1 {
    font-size: 24px;
    font-weight: 500;
    color: #1a1a1a;
    margin: 20px 0;
}
.favorites-empty p {
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    color: #7a7a7a;
}
.favorites-empty-icon {
    position: relative;
    width: 130px;
    height: 130px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 95, 0, 0.05);
    margin: 0 auto;
}
/* End Favorites Page */

/* Login */
.login {
    width: 100%;
    float: left;
    height: 100vh;
    min-height: 960px;
    position: relative;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}
.login::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(26, 26, 26, 0.4), #1a1a1a);
}
.login-wrapper {
    padding: 0 15px;
    width: 100%;
    max-width: 350px;
    position: absolute;
    right: 0;
    left: 0;
    margin: 0 auto;
}
.login-logo {
    text-align: center;
    margin: 100px 0;
}
.login-title {
    position: relative;
    float: left;
    width: 100%;
    margin-bottom: 60px;
}
.login-title h1 {
    font-size: 28px;
    font-weight: 600;
    font-style: normal;
    line-height: normal;
    color: #ffffff;
    margin-bottom: 10px;
}
.login-title p {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.71;
    color: #ffffff;
}
.login-title a {
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    color: #fff;
}
.login-title a:hover {
    color: #ff5f00;
    transition: all .7s ease;
    -webkit-transition: all .7s ease;
    -moz-transition: all .7s ease;
    -o-transition: all .7s ease;
}
.input-wrap {
    float: left;
    width: 100%;
    position: relative;
    margin-bottom: 20px;
}
.login-form {
    position: relative;
    width: 100%;
    float: left;
}
.login-form .form-control {
    height: 44px;
    background-color: #fff;
    border: 0;
    border-radius: 13px;
    box-shadow: none;
    transition: all .7s ease;
    padding: 0 30px 0 15px;
    color: #7a7a7a;
    font-size: 14px;
    font-weight: 500;
}
.login-form .form-control:focus {
    outline: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    color: #7a7a7a;
    transition: all .7s ease;
    -webkit-transition: all .7s ease;
    -moz-transition: all .7s ease;
    -o-transition: all .7s ease;
}
.login-form input.form-control::placeholder {
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    color: #7a7a7a;
}
.submit-button {
    margin-top: 25px;
    float: left;
    width: 100%;
    position: relative;
}
.login-form input.send {
    border: 1px solid #ff5f00;
    background-color: #ff5f00;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    box-shadow: none;
    width: 100%;
    height: 44px;
    border-radius: 13px;
    color: #fff;
    transition: all .7s ease;
    -webkit-transition: all .7s ease;
    -moz-transition: all .7s ease;
    -o-transition: all .7s ease;
}
.login-form input.send:hover {
    background: #fff;
    border: 1px solid #fff;
    color: #ff5f00;
    transition: all .7s ease;
}
.login-form input.send:focus {
    outline: none;
}
.toggle-password {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    cursor: pointer;
}
.tg-pass {
    position: relative;
    cursor: pointer;
}
.pass-eye {
    background-image: url(../img/eye.svg);
    background-position: 0 center;
    width: 21px;
    height: 14px;
}
.pass-eye-off {
    background-image: url(../img/eye-off.svg);
    background-position: 0 center;
    width: 21px;
    height: 14px;
}
.form-forgot {
    width: 100%;
    position: relative;
    float: left;
    margin-top: 30px;
}
.form-forgot a {
    font-family: 'Branding';
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    transition: all .5s ease;
}
.form-forgot a:hover {
    color: #ff5f00;
    transition: all .5s ease;
}
.form-error {
    color: #f00040 !important;
    text-align: left;
    padding: 10px;
    background-color: #fff;
    max-width: 100%;
    top: 0px;
    position: relative;
    margin-bottom: 30px;
}
.form-error::before {
    background-color: #f00040;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
}
.login-other-form {
    float: left;
    position: relative;
    width: 100%;
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.login-social {
    position: relative;
}
.login-social ul li {
    display: inline-block;
}
.login-social ul li:not(:last-child) {
    margin-right: 20px;
}
.login-social ul li a {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.4);
}
.login-social ul li a:hover {
    border: 1px solid rgba(255, 255, 255, 1);
    transition: all .7s ease;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
}
span.login-text {
    display: block;
    font-size: 14px;
    font-weight: 300;
    line-height: normal;
    color: #fff;
}
.login-footer {
    z-index: 2;
    position: absolute;
    text-align: right;
    right: 30px;
    bottom: 30px;
}
.login-footer ul li{
    display: inline-block;
}
.login-footer ul li:not(:last-child) {
    margin-right: 45px;
}
.login-footer ul li a {
    font-size: 12px;
    font-weight: 300;
    color: #7a7a7a;
}
.login-footer ul li a:hover {
    color: #ff5f00;
}
.login-footer p.copyright {
    font-size: 12px;
    font-weight: 300;
    color: #7a7a7a;
    margin-top: 10px;
}
.login-successfully {
    position: relative;
    width: 100%;
    float: left;
    margin-bottom: 100px;
    text-align: center;
}
.login-successfully p {
    font-size: 28px;
    font-weight: 600;
    text-align: center;
    color: #fff;
    margin-top: 40px;
}
.login-mssg {
    position: relative;
    float: left;
    width: 100%;
    margin-top: 30px;
    text-align: center;
    padding: 0 15px;
}
.login-mssg p {
    opacity: 0.5;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    color: #fff;
}
/* End Login */

/* Modals */
.modal-dialog {
    padding: 0 15px;
}
.shop-modal .modal-dialog {
    margin: 60px auto;
    width: 420px;
    max-width: 100%;
}
.flt-modal .modal-dialog {
    margin: 60px auto;
    width: 630px;
    max-width: 100%;
}
.shop-modal .modal-header {
    position: relative;
    padding: 20px;
}
.flt-modal .modal-header {
    position: relative;
    padding: 20px 30px;
}
.flt-modal .modal-body {
    padding: 30px;
}
.modal-content {
    border-radius: 13px;
    box-shadow: none;
    border: none;
}
.close {
    font-size: 50px;
    font-weight: 300;
    line-height: 1;
    color: #fff;
    text-shadow: none;
    filter: none;
    opacity: 1;
    position: absolute;
    right: 0;
    top: -50px;
}
.close:focus,
.close:hover {
    color: #fff;
    border: none;
    outline: none;
}
.modal-header .title {
    font-size: 24px;
    font-weight: 500;
    color: #7a7a7a;
}
.modal-header p {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #7a7a7a;
    margin-top: 10px;
}
.modal-header a {
    font-size: 16px;
    font-weight: 500;
    color: #7a7a7a;
}
.modal-header a svg {
    margin-left: 10px;
}
.modal-header-inner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.modal-backdrop.in {
    background-color: #000;
    opacity: 0.7;
}
.tab-slider-nav {
    width: 100%;
    float: left;
    text-align: center;
    display: flex;
    border-bottom: 1px solid #e3e3e3;
    margin-bottom: 20px;
}
.tab-slider-tabs {
    font-size: 15px;
    padding: 0px;
    list-style: none;
    background: #fff;
    display: inherit;
    position: relative;
    background-color: transparent;
    float: left;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.tab-slider-trigger {
    text-decoration: none;
    padding: 20px;
    display: inline-block;
    position: relative;
    width: 100%;
    z-index: 1;
    font-size: 16px;
    font-weight: 500;
    color: #7a7a7a;
    cursor: pointer;
    transition: color 250ms ease-in-out;
    transition-duration: 0.6s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.tab-slider-trigger.active {
    color: #ff5f00;
}
.tab-slider-trigger.active.active::before,
.tab-slider-trigger.active:hover::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 3px;
    background-color: #ff5f00;
    border-radius: 13px;
    transition: color 250ms ease-in-out;
    transition-duration: 0.6s;
}
.tab-slider-container .title {
    display: block;
    font-size: 18px;
    font-weight: 500;
    color: #7a7a7a;
    float: left;
    width: 100%;
    margin-bottom: 20px;
}
.recipes-md-img {
    float: left;
    height: 230px;
    border-radius: 23px;
    overflow: hidden;
    margin-bottom: 20px;
}
.recipes-md-img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.recipes-md-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.recipes-md-title h1 {
    font-size: 24px;
    font-weight: 500;
    color: #1a1a1a;
}
.recipes-md-ingredients-quantity {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
}
.recipes-md-ingredients-quantity h1 {
    font-size: 18px;
    font-weight: 500;
    color: #7a7a7a;
}
.modal-pre-body {
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
}
.ingredients-quantity {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.ingredients-quantity h1 {
    font-size: 16px;
    font-weight: 500;
    color: #7a7a7a;
}
.add-shoping-list {
    position: relative;
    margin-top: 45px;
}
.add-shoping-list .button {
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ff5f00;
    border-radius: 13px;
    background-color: #ff5f00;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
}
.recipes-md-tools-wrapp {
    position: relative;
    height: 540px;
    overflow-y: auto;
}
.recipes-md-tools-wrapp::-webkit-scrollbar-track {
	border-radius: 3px;
	background-color: #ececec;
}
.recipes-md-tools-wrapp::-webkit-scrollbar {
	width: 6px;
	background-color: #F5F5F5;
}
.recipes-md-tools-wrapp::-webkit-scrollbar-thumb {
	border-radius: 3px;
	background-color: #bebebe;
}
.recipes-md-tools-item {
    position: relative;
    height: 170px;
    border-radius: 13px;
    border: 1px solid #ececec;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 30px;
}
.recipes-md-tools-item img {
    max-width: 100%;
}
.recipes-md-tools-item h1 {
    font-size: 16px;
    font-weight: 500;
    color: #7a7a7a;
    margin-top: 10px;
}
.flt-modal #flt-title {
    font-size: 16px;
    font-weight: 600;
    color: #1a1a1a;
}
.apply-button {
    position: relative;
    margin-top: 60px;
}
.apply-button .button {
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ff5f00;
    border-radius: 13px;
    background-color: #ff5f00;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
}
.flt-modal .range-wrapp {
    margin-bottom: 70px;
}
.modal-filter {
    position: relative;
    padding: 30px 0 30px 30px;
    margin-bottom: 30px;
}
ul.modal-filter-list {
    overflow-x: auto;
    white-space: nowrap;
}
ul.modal-filter-list li {
    display: inline-block;
    margin-right: 50px;
}
.blk-modal .modal-dialog {
    width: 430px;
    max-width: 100%;
    height: auto;
    margin: 150px auto;
}
.blk-modal .modal-body {
    border-radius: 8px;
    background-color: #1a1a1a;
    padding: 40px 30px;
}
.blk-modal .blk-modal-content {
    text-align: center;
}
.blk-modal .blk-modal-content h1 {
    font-size: 24px;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 20px;
}
.blk-modal .blk-modal-content p {
    font-size: 16px;
    font-weight: 500;
    color: #7a7a7a;
    margin-bottom: 30px;
}
.blk-modal .blk-modal-content .content-rate svg:not(:last-of-type) {
    margin-right: 14px;
}
.blk-modal-input {
    margin-top: 40px;
}
.blk-modal-input textarea {
    resize: none;
    height: 120px;
    width: 100%;
    background-color: rgba(122,122,122,0.16);
    border-radius: 9px;
    border: none;
    box-shadow: none;
    padding: 15px;
    font-size: 14px;
    font-weight: 500;
    color: #7a7a7a;
}
.blk-modal-input textarea::placeholder {
    font-size: 14px;
    font-weight: 500;
    color: #7a7a7a;
}
.blk-modal-input textarea:focus {
    outline: none;
    box-shadow: none;
    border: none;
}
.blk-button {
    margin-top: 30px;
}
.blk-button .button {
    padding: 15px 30px;
    display: inline-block;
    text-align: center;
    border: 1px solid #ff5f00;
    border-radius: 13px;
    background-color: #ff5f00;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    margin: 0 auto;
}
.blk-modal .quantity-input {
    padding: 20px 0;
}
.blk-modal .quantity-input .change {
    border: 1px solid #fff;
    color: #fff;
}
.blk-modal .quantity-input .input-box {
    width: 110px;
    color: #fff;
}
.video-modal .modal-dialog {
    width: 770px;
    max-width: 100%;
    margin: 150px auto;
}
.video-modal .modal-body {
    padding: 0;
}
.video-container {
    position: relative;
    width: 100%;
    height: 350px;
    overflow: hidden;
    border-radius: 23px;
}
.video-container video {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.video-container .video-container-overlay {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgb(0, 0, 0);
    opacity: 0.5;
}
.video-container #video-controls {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}
#video-controls .play-pause {
    position: absolute;
    width: 60px;
    height: 60px;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    background-color: rgba(255, 95, 0, 0.7);
    border-radius: 50px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}
#video-controls .play-pause a {
    display: flex;
}
#video-controls .video-controls-bottom {
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
}
#video-controls .show-more {
    margin-right: 20px;
    cursor: pointer;
}
#video-controls .show-more.active svg path{
    fill: #ff5f00;
}
#video-controls .show-more-dialog {
    position: absolute;
    bottom: 50px;
    right: 50px;
    width: 260px;
    padding: 20px;
    border-radius: 6px;
    background-color: #1a1a1a;
    user-select: none;
    display: none;
}
.show-more-dialog-item {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.show-more-dialog-item span {
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
}
.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 22px;
}
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
.slider-switch {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #7a7a7a;
    -webkit-transition: .4s;
    transition: .4s;
}
.slider-switch:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 4px;
    bottom: 4px;
    background-color: #fff;
    -webkit-transition: .4s;
    transition: .4s;
}
input:checked + .slider-switch {
    background-color: #ff5f00;
}
input:focus + .slider-switch {
    box-shadow: 0 0 1px #ff5f00;
}
input:checked + .slider-switch:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
}
.slider-switch.round {
  border-radius: 34px;
}
.slider-switch.round:before {
  border-radius: 50%;
}
.show-more-dialog-item:not(:last-of-type) {
    margin-bottom: 20px;
}
#video-controls .full-screen {
    margin-right: 20px;
    cursor: pointer;
}
#video-controls .video-narrated {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background-color: #1a1a1a;
    z-index: 2;
    user-select: none;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 7px;
    padding-bottom: 7px;
    text-align: center;
}
#video-controls .video-narrated p {
    font-size: 18px;
    font-weight: 500;
    color: #fff;
}

.video-list {
    position: absolute;
    top: 16px;
    left: 16px;
    white-space: nowrap;
    overflow-x: auto;
    width: 96%;
    scrollbar-color: #ff5f00 #ececec;
    scrollbar-width: thin;
    padding: 0 15px 15px 0;
    user-select: none;
}
.video-list::-webkit-scrollbar {
    width: 100%;
    height: 5px;
}
.video-list::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: #ececec;
}
.video-list::-webkit-scrollbar-thumb {
    opacity: 0.8;
    border-radius: 3px;
    background-color: #ff5f00;
}
.video-list li {
    display: inline-block;
}
.video-list .item {
    position: relative;
    width: 105px;
    height: 70px;
    border-radius: 6px;
    display: block;
    cursor: pointer;
    float: none;
    margin-right: 15px;
}
.video-list .item .item-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #000;
    opacity: 0.3;
}
.video-list .item .slider-number {
    position: absolute;
    top: 10px;
    right: 10px;
}
.video-list .item.active .slider-number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    background-color: rgba(255, 95, 0, 0.8);
    border-radius: 50px;
    top: 4px;
    right: 4px;
}
.video-list .item .slider-number span {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
}
.video-list .item.active::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    border: 1px solid #ff5f00;
    border-radius: 6px;
}
.video-list .item.active .item-overlay {
    opacity: 0;
}
.video-list .item img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 6px
}
.video-fullscreen {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 0px;
    z-index: 4;
}
.video-fullscreen-body {
    overflow: hidden;
}
.modal-backdrop.in {
    background-color: rgb(26, 26, 26);
    opacity: 0.9;
}
/* End Modals */

/* Search */
.search {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: rgba(255, 255, 255, 0.99);
    opacity: 0;
    visibility: hidden;
    padding-bottom: 60px;
}
.show {
    opacity: 1;
    visibility: visible;
    overflow-y: visible;
}
.search-on {
    overflow-y: hidden;
}
.search-close {
    position: relative;
    width: 100%;
    cursor: pointer;
    z-index: 2;
}
.search-close svg {
    position: absolute;
    right: 0;
    top: 40px;
}
.search-form {
    position: relative;
    margin: 30px 0;
}
.search-form .search-input {
    position: relative;
    width: 100%;
    height: 44px;
    border-radius: 13px;
    background-color: #f0f0f0;
    font-size: 16px;
    font-weight: 500;
    color: #1a1a1a;
    border: none;
    padding-left: 50px;
}
.search-form .search-input:focus {
    outline: none;
}
.search-form .search-input::placeholder {
    font-size: 16px;
    font-weight: 500;
    color: #1a1a1a;
}
.search-form svg {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
}
.search-menu ul {
    border-bottom: 1px solid #f0f0f0;
}
.search-menu ul li {
    position: relative;
    display: inline-block;
    margin: 0 20px;
}
.search-menu ul li a {
    font-size: 16px;
    font-weight: 500;
    color: #7a7a7a;
    padding: 10px 0;
    display: block;
}
.search-menu ul li.active a,
.search-menu ul li:hover a {
    color: #ff5f00;
}
.search-menu ul li:hover::before,
.search-menu ul li.active::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #ff5f00;
    bottom: -2px;
    left: 0;
    border-radius: 23px;
}
.search-result {
    position: relative;
    padding: 20px 0;
    text-align: right;
}
.search-result p {
    font-size: 14px;
    font-weight: 500;
    color: #7a7a7a;
}
.search-result-title h1 {
    font-size: 16px;
    font-weight: 500;
    color: #7a7a7a;
    margin-bottom: 20px;
}
.search-result-item {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.search-result-img {
    width: 70px;
    height: 80px;
    overflow: hidden;
    border-radius: 13px;
    box-shadow: 0 5px 15px 0 rgba(122, 122, 122, 0.15);
    margin-right: 15px;
}
.search-result-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.search-result-text h1 {
    font-size: 18px;
    font-weight: 500;
    color: #1a1a1a;
}
.search-result-empty {
    margin: 160px 0;
    text-align: center;
}
.search-result-empty h1 {
    font-size: 24px;
    font-weight: 500;
    color: #1a1a1a;
    margin-bottom: 20px;
}
.search-result-empty p {
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    color: #7a7a7a;
}
.search-empty-icon {
    width: 130px;
    height: 130px;
    background-color: rgba(255, 95, 0, 0.05);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    border-radius: 100px;
    margin-bottom: 20px;
}
/* End Search */

/* Settings */
.settings {
    float: left;
    width: 100%;
    position: relative;
}
.settings-title {
    position: relative;
    margin-bottom: 60px;
}
.settings-title h1 {
    font-size: 34px;
    font-weight: 500;
    color: #1a1a1a;
}
.settings-menu ul {
    position: relative;
    border-right: 1px solid #f0f0f0;
    margin-right: 60px;
}
.settings-menu ul li {
    position: relative;
    display: block;
    padding: 12px 0;
}
.settings-menu ul li:not(:last-child) {
    margin-bottom: 10px;
}
.settings-menu ul li a {
    position: relative;
    display: block;
    font-size: 16px;
    font-weight: 500;
    color: #7a7a7a;
}
.settings-menu ul li.active a,
.settings-menu ul li a:hover {
    color: #ff5f00;
}
.settings-menu ul li:not(:last-child).active a::before,
.settings-menu ul li:not(:last-child) a:hover::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 3px;
    top: 0;
    right: -2px;
    background-color: #ff5f00;
}
.settings-menu ul li.last-item {
    margin-top: 84px;
}
.settings-menu ul li.last-item a {
    color: #1a1a1a;
}
.settings-info {
    padding-right: 30px;
    position: relative;
}
.settings-img {
    width: 100%;
    height: 280px;
    overflow: hidden;
    border-radius: 13px;
}
.settings-img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.settings-input {
    position: relative;
    margin-bottom: 30px;
    user-select: none;
}
.settings-input .form-control {
    width: 100%;
    height: 44px;
    padding: 10px 15px;
    padding-right: 40px;
    background-color: #f0f0f0;
    border: 1px solid #f0f0f0;
    border-radius: 13px;
    box-shadow: none;
    -webkit-box-shadow: none;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    color: #1a1a1a;
}
.settings-input .form-control::placeholder {
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    color: #1a1a1a;
}
.settings-input textarea.form-control {
    height: 130px;
    resize: none;
}
.settings-input textarea.form-control::placeholder {
    font-size: 14px;
    font-weight: 500;
    color: #7a7a7a;
}
.settings-input .form-control:focus {
    border: 1px solid #ff5f00;
    background-color: #fff;
    outline: 0;
    transition: all .7s ease;
    -webkit-transition: all .7s ease;
    -moz-transition: all .7s ease;
    -o-transition: all .7s ease;
}
.settings-input label {
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    color: #7a7a7a;
    margin-bottom: 10px;
}
.settings-input select {
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.settings-select {
    position: relative;
}
.settings-input .settings-select::before {
    content: " ";
    background-image: url(../img/down-arrow.svg);
    background-repeat: no-repeat;
    position: absolute;
    width: 12px;
    height: 6px;
    right: 18px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    z-index: 2;
}
.settings-input-wrapper {
    position: relative;
}
.settings-upload {
    margin-top: 10px;
    text-align: center;
}
.settings-upload span {
    font-size: 16px;
    font-weight: 500;
    color: #ff5f00;
    margin-bottom: 10px;
    display: block;
}
.settings-upload label {
    font-size: 16px;
    font-weight: 500;
    color: #ff5f00;
    margin-bottom: 10px;
    display: block;
    cursor: pointer;
}
.settings-upload a {
    font-size: 14px;
    font-weight: 300;
    color: #7a7a7a;
}
.settings-save {
    margin-top: 10px;
}
.settings-save .button {
    height: 44px;
    width: 100%;
    border-radius: 13px;
    border: 0;
    background-color: #ff5f00;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    transition: all .5s ease;
}
.settings-save .button:hover {
    background-color: #ff4000;
    transition: all .5s ease;
}
.settings-save .button:focus {
    outline: none;
}
.settings-notification p {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: #7a7a7a;
}
.settings-notification-head {
    position: relative;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}
.settings-notification-head h1 {
    font-size: 20px;
    font-weight: 500;
    color: #1a1a1a;
    margin-right: 70px;
}
.settings-notification-head .checkbox {
    margin: 0;
    width: 22px;
    height: 22px;
    position: relative;
}
.settings-notification-head .checkbox label {
    min-height: auto;
}
.settings-notification-head .checkbox [type="checkbox"]:checked,
.settings-notification-head .checkbox [type="checkbox"]:not(:checked)
{
    position: absolute;
    left: -9999px;
}
.settings-notification-head .checkbox [type="checkbox"]:checked + label:before,
.settings-notification-head .checkbox [type="checkbox"]:not(:checked) + label:before
{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 22px;
    height: 22px;
    border: 1px solid #7a7a7a;
    border-radius: 6px;
    background: #fff;
}
.settings-notification-head .checkbox [type="checkbox"]:checked + label:after,
.settings-notification-head .checkbox [type="checkbox"]:not(:checked) + label:after
{
    content: '';
    width: 22px;
    height: 22px;
    border-radius: 6px;
    position: absolute;
    top: 0;
    left: 0;
    background: url(../img/checked.svg);
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.settings-notification-head .checkbox [type="checkbox"]:not(:checked) + label:after
{
    opacity: 0;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
}
.settings-notification-head .checkbox [type="checkbox"]:checked + label:after
{
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}
.settings-notification-head .checkbox [type="checkbox"]:disabled:checked + label:before,
.settings-notification-head .checkbox [type="checkbox"]:disabled:not(:checked) + label:before
{
    border-color: #ccc;
    background-color: #eee;
}
.settings-notification-head .checkbox [type="checkbox"]:disabled:checked + label:after
{
    background: #aaa;
}
.settings-feedback {
    text-align: center;
}
.settings-feedback h1 {
    font-size: 24px;
    font-weight: 500;
    color: #1a1a1a;
    margin-bottom: 30px;
}
.settings-feedback .contet-rate-wrap {
    margin-bottom: 60px;
}
.settings-feedback .content-rate-info {
    width: 100%;
    position: relative;
    margin-top: 20px;
}
.settings-feedback .content-rate-info span {
    font-size: 12px;
    font-weight: 500;
    color: #7a7a7a;
}
.settings-feedback .content-rate-info span:not(:last-of-type) {
    margin-right: 120px;
}
.settings-feedback .content-rate svg:not(:last-of-type) {
    margin-right: 20px;
}
.feedback-button {
    margin-top: 40px;
}
.feedback-button .button {
    height: 44px;
    max-width: 100%;
    width: 270px;
    border-radius: 13px;
    background-color: #ff5f00;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    margin: 0 auto;
}
.settings-delete .modal-dialog {
    margin: 230px auto;
    width: 430px;
    max-width: 100%;
}
.settings-delete .modal-content {
    box-shadow: none;
    border-radius: 23px;
}
.settings-delete .modal-body {
    padding: 40px;
    text-align: center;
}
.settings-delete .modal-body h1 {
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    color: #1a1a1a;
}
.settings-delete-buttons {
    position: relative;
    width: 100%;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.settings-delete-buttons .btn-cancel {
    width: 170px;
    height: 44px;
    border-radius: 13px;
    background-color: #fff;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #7a7a7a;
}
.settings-delete-buttons .btn-yes {
    width: 170px;
    height: 44px;
    border-radius: 13px;
    background-color: #ff5f00;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
}
.settings-delete-buttons .btn-cancel:focus,
.settings-delete-buttons .btn-yes:focus {
    outline: none;
}
/* End Settings */

/* Footer */
.footer {
    position: relative;
    width: 100%;
    float: left;
    border-top: 1px solid #ebebeb;
}
.footer-inner {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 55px 0;
    border-bottom: 1px solid #ebebeb;
}
.footer-right {
    position: relative;
}
.footer-right ul li {
    position: relative;
    display: inline-block;
}
.footer-right ul li:first-child {
    padding-right: 30px;
    margin-right: 30px;
    border-right: 1px solid #ebebeb;
}
.footer-right ul li:last-child a {
    padding: 14px 20px;
    color: #ff5f00;
    border-radius: 13px;
    background-color: rgba(255, 95, 0, 0.06);
}
.footer-right ul li a {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: normal;
    color: #7a7a7a;
}
.footer-right ul li a svg {
    margin-right: 10px;
}
.footer-bottom {
    padding: 40px 0;
    width: 100%;
    position: relative;
    float: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.footer-copyright {
    position: relative;
}
.footer-copyright p {
    font-size: 14px;
    font-weight: 500;
    color: #7a7a7a;
}
.footer-links ul li {
    display: inline;
}
.footer-links ul li:not(:last-child) {
    margin-right: 45px;
}
.footer-links ul li a {
    font-size: 14px;
    font-weight: 500;
    color: #7a7a7a;
    transition: all .5s ease;
}
.footer-links ul li a:hover {
    color: #ff5f00;
    transition: all .5s ease;
}
.footer-social ul li {
    display: inline-block;
}
.footer-social ul li:not(:last-child) {
    margin-right: 20px;
}
.footer-social ul li svg path {
    transition: all .5s ease;
}
.footer-social ul li svg:hover path {
    fill: #ff5f00;
    transition: all .5s ease;
}
.footer-app ul li {
    display: inline-block;
}
.footer-app ul li:not(:last-child) {
    margin-right: 20px;
}

/* Get App Section */
.get-app {
    position: relative;
    width: 100%;
    float: left;
    background-color: #ff5f00;
    padding: 60px 0;
}
.get-app-img {
    position: absolute;
    top: -238px;
}
.get-app-content-wrapp {
    position: relative;
    padding: 0 90px;
}
.get-app-content {
    margin-bottom: 45px;
}
.get-app-content h1 {
    font-size: 36px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 20px;
}
.get-app-content p {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #fff;
}
.get-app-badge a:not(:last-of-type) {
    margin-right: 30px;
}
/* End Get App Section */
/* End Footer */

@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-moz-keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-webkit-keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-o-keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity: 1;
    }
}

/* 404 Page */
.not-found {
    position: relative;
    width: 100%;
    float: left;
    padding: 180px 0 120px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.not-found-content {
    position: relative;
    text-align: center;
}
.not-found-title {
    position: relative;
}
.not-found-content h1 {
    font-size: 150px;
    font-weight: 600;
    line-height: normal;
    color: #ff5f00;
}
.not-found-content span.shadow-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 300px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.09;
    letter-spacing: normal;
    text-align: left;
    color: #f4f4f4;
    z-index: -1;
}
.not-found-text {
    position: relative;
    margin-top: 100px;
}
.not-found-text h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
    color: #1a1a1a;
    margin-bottom: 20px;
}
.not-found-text p {
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    color: #7a7a7a;
}
.not-found-button {
    text-align: center;
    margin-top: 40px;
}
.not-found-button .button {
    border: 1px solid #ff5f00;
    border-radius: 13px;
    background-color: #ff5f00;
    color: #fff;
    padding: 15px;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
    min-width: 115px;
}
/* End 404 Page */

/* Privacy & Terms Page */
.page-content {
    position: relative;
    float: left;
    width: 100%;
    padding: 70px 0 100px;
}
.page-content-inner h1 {
    font-size: 34px;
    font-weight: 500;
    line-height: normal;
    color: #1a1a1a;
    margin-bottom: 80px;
}
.page-content-inner h2 {
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    color: #1a1a1a;
    margin-bottom: 20px;
}
.page-content-inner p {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.75;
    color: #7a7a7a;
    margin-bottom: 60px;
}
/* End Privacy & Terms Page */

/* Tooltip */
[data-tooltip] {
    position: relative;
    cursor: pointer;
}
[data-tooltip]:before,
[data-tooltip]:after {
    visibility: hidden;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    pointer-events: none;
}
[data-tooltip]:before {
    position: absolute;
    bottom: 120%;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 5px;
    padding: 15px;
    width: 100%;
    border-radius: 4px;
    background-color: #1a1a1a;
    /* background-color: rgba(26, 26, 26, 0.8); */
    color: #fff;
    content: attr(data-tooltip);
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
}
[data-tooltip]:after {
    position: absolute;
    bottom: 120%;
    left: 50%;
    margin-left: -5px;
    width: 0;
    border-top: 5px solid #1a1a1a;
    /* border-top: 5px solid rgba(26, 26, 26, 0.8); */
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    content: " ";
    font-size: 0;
    line-height: 0;
}
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
    visibility: visible;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
}
.video-tooltip {
    position: inherit !important;
}
.video-tooltip-inner {
    position: absolute;
    left: 10px;
    bottom: 70px;
    width: calc(100% - 20px);
    background-color: #ff5f00;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    color: #ffffff;
    padding: 5px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;
}
.video-tooltip a::after {
    content: " ";
    position: absolute;
    top: 3px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    border-top: 5px solid #ff5f00;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;
}
.video-tooltip:hover .video-tooltip-inner,
.video-tooltip:hover a::after {
    visibility: visible;
    opacity: 1;
}
/* End Tooltip */

/* Banner Mobile */
.banner-mobile {
    position: relative;
    float: left;
    width: 100%;
    display: none;
    /* background-color: #f4f4f4;
    padding: 15px; */
}
.banner-mobile-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F4F4F4;
    padding: 15px;
}
.banner-mobile-left {
    display: flex;
    align-items: center;
}
.banner-close {
    margin-right: 10px;
}
.banner-logo {
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 10px;
    margin-right: 10px;
}
.banner-logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.banner-content h1 {
    font-size: 18px;
    font-weight: 600;
    color: #1a1a1a;
}
.banner-content p {
    font-size: 14px;
    font-weight: 500;
    color: #7a7a7a;
}
.banner-content span {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #7a7a7a;
}
.banner-mobile-right a {
    font-size: 16px;
    font-weight: 500;
    color: #ff5f00;
}
/* End Banner Mobile */

/* Responsive */
@media (max-width: 1199px) {
    .cookbook-month-content {
        padding-top: 0px;
    }
}
@media (min-width:992px) and (max-width:1199px) {
    .cookbooks-content h1 {
        width: 200px;
    }
    .cookbooks-content span {
        width: 200px;;
    }
    .recipes-item-content-details-inner ul li:not(:last-child) {
        margin-right: 5px;
    }
    .section-top-190 {
        margin-top: 120px;
    }
    .header-inner {
        padding: 0;
    }
    .header .nav-menu-wrapp {
        margin-left: 180px;
    }
    .header .nav-menu ul li:not(:last-child) {
        margin-right: 25px;
    }
    .get-app-img {
        top: -145px;
    }
    .get-app-content-wrapp {
        padding: 0 60px;
    }
    .recipes-info-body-inner {
        margin-right: 15px;
    }
    .recipes-rate {
        margin-right: 15px;
    }
    .recipes-content-ft {
        flex-direction: column;
        align-items: flex-start;
    }
    .recipes-content-ft-inner {
        margin-bottom: 10px;
    }
    .author-profile-link a.icon {
        width: 34px;
        height: 34px;
        margin-right: 10px;
    }
    .authors-content .authors-title {
        width: 200px;
    }
    .cookbooks-content-footer {
        flex-direction: column;
        align-items: flex-start;
    }
    .cookbooks-content p {
        margin-bottom: 5px;
    }
    .filters-category-item {
        flex: 0 1 calc(33.33% - 20px);
    }
    .recipes-extra-ingredients-item {
        flex-direction: column;
        align-items: flex-start;
    }
    .recipes-extra-ingredients-quantity {
        margin-top: 15px;
    }
}
@media (min-width:768px) and (max-width:991px) {
    .header-inner {
        padding: 0;
    }
    .header .nav-end ul li {
        margin-right: 0px;
    }
    .header .nav-end ul li:not(:last-of-type) {
        margin-right: 15px;
    }
    .header .nav-menu-wrapp {
        margin-left: 140px;
    }
    .header .nav-menu ul li:not(:last-child) {
        margin-right: 25px;
    }
    .section-top-190 {
        margin-top: 90px;
    }
    .section-top-120 {
        margin-top: 60px;
    }
    .section-top-80 {
        margin-top: 40px;
    }
    .main-explore-slider .item {
        height: 500px;
    }
    .main-explore-slider .item .content {
        top: 50%;
        transform: translateY(-50%);
    }
    .main-explore-slider .item .content .content-discover {
        margin-top: 30px;
    }
    .filters-button-cookbook {
        margin-top: 40px;
    }
    .authors-banner {
        height: 500px;
    }
    .author-profile-img {
        margin: -185px auto 30px;
    }
    .author-profile-info,
    .author-profile-subsribe {
        text-align: center;
    }
    .blog-single-banner {
        height: 500px;
    }
    .recipes-time-item:not(:last-of-type) {
        margin-right: 70px;
    }
    .settings-menu ul {
        margin-right: 0px;
    }
    .settings-upload {
        margin-bottom: 40px;
    }
    .settings-img {
        text-align: center;
    }
    .settings-img img {
        width: auto;
    }
    .get-app-img {
        position: relative;
        top: 0;
    }
    .get-app-content-wrapp {
        padding: 0;
    }
}
@media (max-width: 991px) {
    .main-menu-indicator {
        display: block;
    }
    .header .nav-mobile {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .header .nav-menu-wrapp {
        -webkit-transform: translatex(100%);
        -ms-transform: translatex(100%);
        transform: translatex(100%);
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: rgba(26, 26, 26, 0.96);
        z-index: 99;
        padding: 0;
        -webkit-transition: -webkit-transform .4s ease-in-out;
        transition: -webkit-transform .4s ease-in-out;
        -o-transition: transform .4s ease-in-out;
        transition: transform .4s ease-in-out;
        transition: transform .4s ease-in-out, -webkit-transform .4s ease-in-out;
        margin-top: 0;
        margin-left: 0;
    }
    .header .nav-menu-wrapp.white-bg {
        background: rgba(255, 255, 255, 0.96);
    }
    .header .nav-menu-wrapp.is-open {
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }
    body.is-open {
        overflow-y: hidden;
    }
    .header-inner {
        padding: 0;
        height: 60px;
    }
    .header .nav-menu ul {
        text-align: center;
        margin-top: 60px;
    }
    .header .nav-end ul li {
        margin-right: 25px;
    }
    .header .nav-menu ul li:not(:last-child) {
        display: block;
        margin-right: 0;
        margin-bottom: 40px
    }
    .header .nav-menu ul li a {
        color: #fff;
        font-size: 18px;
    }
    .header .nav-menu-wrapp.white-bg .nav-menu ul li a {
        color: #1a1a1a;
    }
    .header .nav-menu-wrapp.white-bg .nav-menu ul li.active a {
        color: #ff5f00;
    }
    .header .nav-menu ul li.active::before,
    .header .nav-menu ul li:hover::before {
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
    }
    .header-inner .nav-end {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .header-inner .nav-end .mb-hidden {
        display: none;
    }
    .recipes-profile-info {
        display: flex;
        flex-direction: column;
        margin-top: 240px;
    }
    .recipes-profile-inner-st {
        width: 100%;
        order: 2;
    }
    .recipes-profile-inner-nd {
        width: 100%;
        order: 1;
    }
    .recipes-tags-single {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .cookbook-profile-img {
        height: 400px;
        margin-bottom: 20px;
    }
    .filters-category-item {
        flex: 0 1 calc(33.33% - 20px);
    }
    .footer-bottom {
        flex-direction: column;
    }
    .footer-social {
        order: 1;
        margin-bottom: 15px;
    }
    .footer-app {
        order: 2;
        margin-bottom: 15px;
    }
    .footer-links {
        order: 3;
        margin-bottom: 15px;
    }
    .footer-copyright {
        order: 4;
    }
}
@media (max-width: 767px) {

    .footer-app ul li:not(:last-child) {
        margin-right: 10px;
    }
    .recipes-item-content-details-inner ul li:not(:last-child) {
        margin-right: 10px;
    }
    .banner-mobile {
        display: block;
    }
    .recipes-extra-banner .img-syle {
        height: 400px;
    }
    .recipes-extra-banner img {
      height: 100%;
      object-fit: cover;
      object-position: center;
      width: 100%;
    }
    .section-top-190 {
        margin-top: 400px;
    }
    .section-top-120 {
        margin-top: 60px;
    }
    .section-top-110 {
        margin-top: 40px;
    }
    .section-top-90 {
        margin-top: 30px;
    }
    .section-top-80 {
        margin-top: 50px;
    }
    .mg-top-130 {
        margin-top: 100px;
    }
    .header .logo {
        height: 85px;
        width: 95px;
    }
    .header .logo img {
        max-width: 70%;
    }
    .main-title h1 {
        font-size: 24px;
    }
    .cookbooks-slider .slick-prev {
        top: -72px;
    }
    .cookbooks-slider .slick-next {
        top: -72px;
    }
    .gen-link-more {
        text-align: center;
    }
    .slick-controls {
        display: block;
    }
    .main-explore-slider .item {
        height: 432px;
    }
    .main-explore-slider .item .content {
        top: 50%;
        transform: translateY(-50%);
        padding-left: 0px;
    }
    .main-explore-slider .item .content h1 {
        font-size: 26px;
        margin-bottom: 15px;
    }
    .main-explore-slider .item .content p {
        font-size: 16px;
    }
    .main-explore-slider .item .content .content-discover {
        margin-top: 60px;
    }
    .main-explore-slider .item .content .content-discover .button {
        font-size: 14px;
        padding: 10px 20px;
    }
    .second-explore-slider {
        display: none;
    }
    .blog-slider .main-explore-slider .item .content {
        top: 35%;
    }
    .blog-slider .main-explore-slider .item .content .content-discover {
        margin-top: 48px;
    }
    .blog-slider .second-explore-slider {
        display: block;
        width: 100%;
        right: auto;
        left: 15px;
    }
    .blog-slider .second-explore-slider .item {
        width: 185px;
        height: 100px;
        margin-right: 25px;
    }
    .filters-title {
        margin-bottom: 40px;
    }
    .author-services-item {
        margin-bottom: 60px;
    }
    .author-services-content h1 {
        margin: 15px 0;
    }
    .authors-banner {
        height: 432px;
    }
    .author-banner-content h1 {
        font-size: 26px;
        font-weight: 700;
        margin-bottom: 0px;
    }
    .author-banner-content p {
        font-size: 16px;
    }
    .author-banner-content {
        top: 50%;
    }
    .authors-content .authors-content {
        display: none;
    }
    .author-profile-img {
        margin: -185px auto 30px;
    }
    .author-profile-info,
    .author-profile-subsribe {
        text-align: center;
    }
    .author-profile-subsribe a {
        width: 100%;
    }
    .blog-single-banner {
        height: 432px;
    }
    .blog-single-banner-content {
        bottom: 30px;
    }
    .blog-single-banner-content h1 {
        font-size: 26px;
        font-weight: 700;
        margin-bottom: 20px;
    }
    .blog-single-banner-content .author,
    .blog-single-banner-content .date {
        font-size: 16px;
    }
    .blog-single-content p {
        font-size: 16px;
    }
    .cookbook-profile-info {
        display: flex;
        flex-direction: column;
    }
    .cookbook-profile-info-inner-st {
        width: 100%;
        order: 2;
    }
    .cookbook-profile-info-inner-nd {
        width: 100%;
        order: 1;
    }
    .cookbook-profile-nav ul {
        overflow: auto;
        overflow-y: hidden;
        white-space: nowrap;
    }
    .cookbook-profile-nav ul li {
        display: inline-block;
    }
    .cookbook-profile-nav ul li.active::before,
    .cookbook-profile-nav ul li:hover::before {
        bottom: 0;
    }
    .cookbooks-profile-stats span.stats {
        font-size: 16px;
    }
    .cookbooks-profile-stats span.stats svg {
        margin-right: 0px;
    }
    .cookbook-profile-share ul li:not(:last-child) {
        margin-right: 5px;
    }
    .cookbook-profile-rate {
        flex-direction: column;
        align-items: flex-start;
    }
    .cookbook-profile-rate h2 {
        margin-right: 0px;
        margin-bottom: 10px;
    }
    .favorites-empty {
        margin-bottom: 0px;
    }
    .cookbook-profile-content {
        flex-direction: column;
        align-items: flex-start;
    }
    .cookbook-author-info {
        margin-bottom: 30px;
    }
    .recipes-profile-banner {
        height: 540px;
    }
    .recipe-profile-img {
        margin-bottom: 20px;
    }
    .recipes-play {
        float: left;
        left: 50%;
        transform: translateX(-50%);
    }
    .recipes-table {
        margin-bottom: 40px;
    }
    .recipes-table table tr th,
    .recipes-table table tr td {
        text-align: center;
    }
    .recipes-time-wrap {
        justify-content: space-between;
        padding: 40px 30px;
    }
    .recipes-time-item:not(:last-of-type) {
        margin-right: 0px;
    }
    .recipes-extra-banner {
        height: 400px;
        margin-bottom: 30px;
    }
    .recipes-buy-img {
        height: 270px;
    }
    .recipes-extra-table {
        width: 100%;
    }
    .recipes-howto-shade {
        display: none;
    }
    .recipes-tools-wrapp {
        overflow: hidden;
        height: 450px;
        border-radius: 16px;
        border: 1px solid rgba(122, 122, 122, 0.2);
        padding: 10px 4px 0px 0px;
        margin-bottom: 30px;
    }
    .recipes-tools-inner {
        position: relative;
        height: 420px;
        overflow: auto;
        scrollbar-color: #ececec #7a7a7a;
        scrollbar-width: thin;
    }
    .recipes-tools-inner::-webkit-scrollbar {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 4px;
        height: 410px;
    }
    .recipes-tools-inner::-webkit-scrollbar-track {
        border-radius: 3px;
        background-color: #ececec;
    }
    .recipes-tools-inner::-webkit-scrollbar-thumb {
        opacity: 0.8;
        border-radius: 3px;
        background-color: #7a7a7a;
    }
    .recipes-comment-item {
        display: block;
    }
    .recipes-comment-info {
        width: 100%;
        margin-bottom: 20px;
    }
    .recipes-comment-info-inner {
        margin-bottom: 10px;
    }
    .recipes-comment-content {
        width: 100%;
    }
    .recipes-comment-info span.date {
        font-size: 14px;
    }
    .filters-head {
        flex-direction: column;
        align-items: flex-start;
    }
    .filters-button {
        width: 100%;
    }
    .filters-button .button {
        width: 100%;
    }
    .filters-title {
        width: 100%;
        justify-content: space-between;
    }
    .filters-category-item {
        flex: 0 1 calc(50% - 20px)
    }
    .favorites-title h1 {
        font-size: 24px;
    }
    .favorites-nav ul {
        margin-bottom: 30px;
    }
    .favorites-nav ul li {
        width: 50%;
        float: left;
    }
    .favorites-nav ul li:not(:last-child) {
        margin-right: 0;
    }
    .favorites .cookbooks-item {
        margin-bottom: 40px;
        word-wrap: break-word;
    }
    .favorites .cookbooks-img {
        height: 163px;
    }
    .favorites .cookbooks-content-footer {
        display: block;
    }
    .favorites .cookbooks-content p {
        margin-bottom: 15px;
    }
    .search-form .search-input {
        width: 90%;
    }
    .search-menu ul {
        overflow: auto;
        overflow-y: hidden;
        width: calc(100% + 10px);
        white-space: nowrap;
    }
    .settings-menu ul {
        margin-right: 0px;
        margin-bottom: 60px;
        overflow: auto;
        overflow-y: hidden;
        white-space: nowrap;
        border-bottom: 1px solid #f0f0f0;
        border-right: 0;
    }
    .settings-menu ul li {
        display: inline-block;
    }
    .settings-menu ul li:not(:last-child) {
        margin-bottom: 0px;
        margin-right: 20px;
    }
    .settings-menu ul li.last-item {
        display: none;
    }
    .settings-menu ul li:not(:last-child).active a::before,
    .settings-menu ul li:not(:last-child) a:hover::before {
        height: 3px;
        width: 100%;
        top: auto;
        bottom: -12px;
        right: auto;
    }
    .settings-upload {
        margin-bottom: 30px;
    }
    .settings-img {
        text-align: center;
    }
    .settings-img img {
        width: auto;
    }
    .login-footer {
        width: 100%;
        right: auto;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
    }
    /* #video-controls .video-narrated {
        width: 100%;
        bottom: 60px;
        padding: 0 5px;
    } */
    .get-app {
        padding: 24px 0;
    }
    .get-app-img {
        top: auto;
        bottom: 24px;
    }
    .get-app-content-wrapp {
        padding: 0;
    }
    .get-app-badge a:not(:last-of-type) {
        margin-right: 5px;
    }
    .footer-inner {
        flex-direction: column;
        padding: 40px 0;
        text-align: center;
    }
    .footer-logo {
        width: 100%;
        margin-bottom: 40px;
    }
    .footer-right {
        width: 100%;
    }
    .footer-links ul li:not(:last-child) {
        margin-right: 20px
    }
    .footer-right ul li:first-child {
        margin-right: 0px;
        padding-right: 0px;
        margin-bottom: 30px;
        border-right: 0px;
    }
    #range-time .ui-slider-handle,
    #range-cal .ui-slider-handle {
        margin: -6px 0 0 -6px;
        width: 20px;
        height: 20px;
    }
    .message-alert-content {
        right: 0px;
    }
}
@media (max-width:374px) {
    .header .nav-end ul li:not(:last-child) {
        margin-right: 10px;
    }
    .header .nav-end ul li {
        margin-right: 10px;
    }
    .cookbook-month-img {
        width: 100%;
    }
    .filters-category-item {
        flex: 0 1 calc(100% - 20px)
    }
}
/* End Responsive */

.department-title-style {
  margin-top: 20px;
}

.video-react {
  font-size: 0px !important;
}

.video-react .video-react-video {
  height: 460 !important;
}

.fullscreen-video {
    height: 100vh !important;
}

.fullscreen-list {
    height: 100vh !important;
}

.fullscreen .modal-dialog {
    width: 100%;
    margin: 0;
}

.recipe-item {
	min-height: 480px;
}

.video-cover video,
.recipe-profile-single-video .recipe-profile-img-wrap video {
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
  border-radius: 20px;
}
.recipes-item .recipes-item-img .recipes-content {
    margin: 0;
    display: block;
}
.recipes-item .recipes-item-img .recipes-content .carousel-root {
    height: 280px;
}
.recipes-item .recipes-item-img .recipes-content .carousel-root .carousel {
    height: 100%;
}
.recipes-item .recipes-item-img .recipes-content .carousel-root .carousel .slide,
.recipes-item .recipes-item-img .recipes-content .carousel-root .carousel .slide .img-syle {
    height: 280px;
}
.recipes-item .recipes-item-img .recipes-content .carousel-root .carousel .slide .img-syle img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
@media (max-width: 767px) {
    .recipe-item {
        min-height: inherit;
        display: flex;
    }
    .recipes-item {
        margin-bottom: 80px;
    }
}

.cookbook-month-img img {
  width: 90%;

}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0.25rem;
  overflow: hidden;
}
